import styled from "styled-components";

export const Container = styled.div`
  width: 700px;
  height: 990px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  border: 2px solid ${({ theme }) => theme.COLORS.GRAY_200};

  iframe {
    width: 100%;
    height: ${({ viewOnly }) => (viewOnly ? "calc(100% + 35px)" : "100%")};
    position: absolute;
    top: 0;
    border: none;
  }

  #toolbarViewerRight {
    display: none;
  }

  @media (max-width: 767px) {
    width: 100%;
    height: 85vh;
    margin-top: 30px;
  }
`;
