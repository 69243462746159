import theme from "../../styles/theme";
import headerBar from "../../assets/header-bar.png";
import logo from "../../assets/vertical.png";
import { Container } from "./styles";

export default function PrintHeader() {
  return (
    <Container theme={theme} className="print">
      <img src={headerBar} alt="barra de cabeçalho" className="header-bar" />
      <img src={logo} alt="logo" className="logo" />
    </Container>
  );
}
