import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import ptBR from "date-fns/locale/pt-BR";

import { Container } from "./styles";
import theme from "../../../../styles/theme";

registerLocale("pt-BR", ptBR);
setDefaultLocale("pt-BR");

export default function ReportDatePicker({ form, setForm, ...rest }) {
  const [value, setValue] = useState(null);

  useEffect(() => {
    if (!(value instanceof Date) || isNaN(value.getTime())) return;

    setForm({
      ...form,
      [rest.id]: value,
    });
  }, [value]);

  useEffect(() => {
    const currentTimestamp = new Date(form.date)?.getTime();
    const startTimestamp = form.startDate?.getTime();
    const endTimestamp = form.endDate?.getTime();

    if (currentTimestamp < startTimestamp || currentTimestamp > endTimestamp)
      setForm({
        ...form,
        [rest.id]: null,
      });
  }, [form.startDate, form.endDate]);

  function handleSelect(date) {
    setValue(date);
  }

  return (
    <Container theme={theme} type={rest.type} disabled={rest.disabled}>
      <DatePicker
        selected={value}
        onChange={handleSelect}
        showTimeSelect={rest.type === "datetime" ? true : false}
        showMonthDropdown
        showYearDropdown
        timeInputLabel="Hora Precisa:"
        timeCaption="Hora"
        showTimeInput={rest.type === "datetime" ? true : false}
        dateFormat={
          rest.type === "datetime" ? "dd/MM/yyyy - HH:mm" : "dd/MM/yyyy"
        }
        className="calendar"
        id="date"
        placeholderText="Selecione uma data..."
        minDate={rest.minDate}
        maxDate={rest.maxDate}
        locale="pt-BR"
        {...rest}
      />
    </Container>
  );
}
