import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { MdOutlineFactCheck } from "react-icons/md";
import { RiShipLine } from "react-icons/ri";
import { RxIdCard } from "react-icons/rx";
import { FaPlusCircle, FaMinusCircle } from "react-icons/fa";
import { FaHelmetSafety } from "react-icons/fa6";
import { FiUser } from "react-icons/fi";
import dayjs from "dayjs";
import "dayjs/locale/pt-br";

import UserContext from "../../../../../contexts/UserContext";
import useInspectors from "../../../../../hooks/api/inspection/useInspectors";
import useInspectionData from "../../../../../hooks/api/inspection/useInspectionData";
import useScheduleInspection from "../../../../../hooks/api/inspection/useScheduleInspection";
import inspectionUtils from "../../../../../utils/inspection-utils";
import { Section } from "../../../../../components/Section";
import theme from "../../../../../styles/theme";
import { Divider, Edit, FormsWrapper, Row } from "../../styles";
import { Form } from "../../styles";
import Label from "../../../../../components/forms/inspection-form/Label";
import { Container, ProductComment, ProductSelectorWrapper } from "./styles";
import Select from "../../../../../components/forms/inspection-form/Select";
import Input from "../../../../../components/forms/inspection-form/Input";
import Button from "../../../../../components/forms/inspection-form/Button";
import Textarea from "../../../../../components/forms/inspection-form/Textarea";
import toastUtils from "../../../../../utils/toast-utils";
import useInspectionDetails from "../../../../../hooks/api/inspection/useInspectionDetails";
import useDeleteDetails from "../../../../../hooks/api/inspection/useDeleteDetails";
import ConfirmationMessage from "../../../../../components/ConfirmationMessage";

export default function AnalysisDetails() {
  const { inspectionId } = useParams();
  const { userData } = useContext(UserContext);
  const navigate = useNavigate();
  const det = useLocation().search.replace("?det=", "");
  const allowed =
    userData.permission?.label === "MASTER" ||
    userData.permission?.label === "APROVADOR";
  const { getInspectors } = useInspectors();
  const { getInspectionData } = useInspectionData();
  const { getInspectionDetails } = useInspectionDetails();
  const { scheduleInspection } = useScheduleInspection();
  const [title, setTitle] = useState("Vistorias/Agendadas");
  const [editing, setEditing] = useState(false);
  const [inspection, setInspection] = useState(
    inspectionUtils.emptyInspectionData
  );
  const [inspectionBackup, setInspectionBackup] = useState({});
  const [inspectors, setInspectors] = useState([]);
  const [coordinators, setCoordinators] = useState(
    inspectionUtils.emptyInspector
  );
  const [form, setForm] = useState(inspectionUtils.emptyAnalysisForm);
  const [formBackup, setFormBackup] = useState({});
  const [status, setStatus] = useState(0);

  dayjs.locale("pt-br");

  useEffect(() => {
    loadInspectionData();
    loadCoordinators();
  }, []);

  useEffect(() => {
    const inspectorList = inspectionUtils.emptyInspector.concat(
      coordinators.filter((coordinator) =>
        form.reports.every((report) =>
          coordinator.qualifications.some(
            (qualification) => report.product.id === qualification.productId
          )
        )
      )
    );

    if (
      !inspectorList.find(
        (inspector) => inspector.value === form.inspector.value
      )
    )
      setForm({
        ...form,
        inspector: inspectionUtils.emptyInspectionForm.inspector,
      });

    setInspectors(inspectorList);
  }, [form.reports]);

  async function loadInspectionData() {
    try {
      const response = await getInspectionData(inspectionId);
      const details = await getInspectionDetails(det);

      if (response && details) {
        setInspection(response);
        setInspectionBackup(response);

        const result = {
          ...form,
          request: response.request,
          obs: response.obs,
          coordinator: {
            label: response.coordinator.name,
            value: response.coordinator.id,
          },
          inspector: {
            label: details.inspector.name,
            value: details.inspector.id,
          },
          reports: details.reports,
        };

        setForm(result);
        setFormBackup(result);
        setStatus(details.status);
        setTitle(
          `${title}/${response.id} - ${response.vessel.name} (${response.company.name})/Detalhes`
        );
      } else {
        setForm(inspectionUtils.emptyInspectionForm);
      }
    } catch (error) {
      toastUtils.toaster({
        message: error.response.data,
        type: toastUtils.type.error,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    }
  }

  async function loadCoordinators() {
    let skip = 0;
    let take = 1000;

    try {
      const response = await getInspectors({
        skip,
        take,
      });
      if (response) {
        setCoordinators(
          inspectionUtils.emptyInspector.concat(
            response.map((inspector) => ({
              ...inspector,
              label: inspector.name,
              value: inspector.crea.id,
            }))
          )
        );
      }
    } catch (error) {
      toastUtils.toaster({
        message: error.response.data,
        type: toastUtils.type.error,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    }
  }

  function handleForm({ name, value }) {
    setForm({ ...form, [name]: value });
  }

  async function handleSubmit(e) {
    e.preventDefault();

    const body = {
      inspectionId: form.request.value,
      obs: form.obs,
      forms: form.reports.map((report) => ({
        detailId: report.detailId || 0,
        inspected: false,
        pendent: false,
        obs: report.obs,
        status: inspectionUtils.status.AGUARDANDO_ANALISE_VISTORIADOR,
        inspectorId: form.inspector.value,
        formId: report.report.id,
      })),
    };

    try {
      await scheduleInspection(body);

      navigate("/painel/vistorias/agendadas");

      toastUtils.toaster({
        message: "Vistoria agendada com sucesso!",
        type: toastUtils.type.success,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    } catch (error) {
      toastUtils.toaster({
        message: error.response.data,
        type: toastUtils.type.error,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    }
  }

  return (
    <Section title={title}>
      <Container theme={theme}>
        <FormsWrapper>
          {allowed &&
            status <= inspectionUtils.status.AGUARDANDO_ANALISE_COORDENADOR && (
              <Edit
                theme={theme}
                onClick={(e) => {
                  e.preventDefault();

                  if (editing) {
                    setForm(formBackup);
                    setInspection(inspectionBackup);
                  } else if (!allowed) {
                    toastUtils.toaster({
                      message:
                        "Você não tem permissão para editar os dados desta vistoria!",
                      type: toastUtils.type.error,
                      position: toastUtils.position.topCenter,
                      theme: toastUtils.theme.colored,
                    });

                    return;
                  }

                  setEditing(!editing);
                }}
              >
                {editing ? "Cancelar" : "Editar"}
              </Edit>
            )}

          <Form className="inspectionForm" onSubmit={handleSubmit}>
            <div>
              <h3>Dados da Análise</h3>

              <Row>
                <span className="company">
                  <Label id="company" text="Armador" />
                  <Input
                    id="company"
                    placeholder="Armador"
                    type="text"
                    icon={RxIdCard}
                    disabled
                    value={inspection.company.name}
                  />
                </span>

                <span className="vessel">
                  <Label id="vessel" text="Embarcação" />
                  <Input
                    id="vessel"
                    placeholder="Embarcação"
                    type="text"
                    icon={RiShipLine}
                    disabled
                    value={inspection.vessel.name}
                  />
                </span>
              </Row>

              <Row>
                <span className="request">
                  <Label id="request" text="Ordem de Serviço" />
                  <Input
                    id="request"
                    placeholder="Ordem de Serviço"
                    type="text"
                    icon={MdOutlineFactCheck}
                    disabled
                    required
                    value={form.request.label}
                  />
                </span>
              </Row>

              <Row>
                <span className="textarea no-label">
                  <Textarea
                    id="obs"
                    placeholder="Observações..."
                    disabled={!(editing && allowed)}
                    value={form.obs}
                    required
                    form={form}
                    onChange={(e) =>
                      handleForm({
                        name: e.target.id,
                        value: e.target.value,
                      })
                    }
                  />
                </span>
              </Row>
            </div>

            {inspection.pendingInspections.length > 0 && (
              <>
                <Divider theme={theme} />

                <div>
                  <h3>Serviço(s) Pendente(s)</h3>

                  {inspection.pendingInspections.map((product, index) => (
                    <ProductSelector
                      key={index}
                      index={index}
                      detailId={0}
                      report={product.report}
                      product={product.product}
                      obs={product.obs}
                      form={form}
                      setForm={setForm}
                      inspection={inspection}
                      setInspection={setInspection}
                      editing={editing}
                      list="pending"
                      loadInspectionData={loadInspectionData}
                    />
                  ))}
                </div>
              </>
            )}

            <Divider theme={theme} />

            <div>
              <h3>Serviço(s) Atribuído(s)</h3>

              {form.reports.map((report, index) => (
                <ProductSelector
                  key={index}
                  index={index}
                  detailId={report.detailId}
                  report={report.report}
                  product={report.product}
                  obs={report.obs}
                  form={form}
                  setForm={setForm}
                  inspection={inspection}
                  setInspection={setInspection}
                  editing={editing}
                  list="scheduled"
                  loadInspectionData={loadInspectionData}
                />
              ))}
            </div>

            <Divider theme={theme} />

            <div>
              <h3>Responsáveis Técnicos</h3>

              <Row className="responsible">
                <span className="inspector">
                  <Label id="inspector" text="Analista*" />
                  <Select
                    id="inspector"
                    placeholder="Selecione..."
                    icon={FaHelmetSafety}
                    enabled={editing && allowed && form.reports.length > 0}
                    list={inspectors}
                    selected={form.inspector}
                    required
                    form={form}
                    setForm={setForm}
                    handleForm={handleForm}
                  />
                </span>

                <span className="coordinator">
                  <Label id="coordinator" text="Coordenador" />
                  <Select
                    id="coordinator"
                    placeholder="Selecione..."
                    icon={FiUser}
                    enabled={false}
                    list={coordinators}
                    selected={form.coordinator}
                    required
                    form={form}
                    setForm={setForm}
                    handleForm={handleForm}
                  />
                </span>
              </Row>
            </div>

            {editing && (
              <Button
                className="submit"
                title="Atualizar"
                enabled={editing && allowed}
              />
            )}
          </Form>
        </FormsWrapper>
      </Container>
    </Section>
  );
}

function ProductSelector({
  detailId,
  report,
  product,
  obs,
  index,
  form,
  setForm,
  inspection,
  setInspection,
  editing,
  list,
  loadInspectionData,
}) {
  const { deleteDetails } = useDeleteDetails();
  const { inspectionId } = useParams();
  const [removing, setRemoving] = useState(false);

  function handleComment(value) {
    if (list === "scheduled") {
      const reports = [...form.reports];

      reports[index] = {
        ...reports[index],
        obs: value,
      };

      setForm({
        ...form,
        reports,
      });
    } else {
      const reports = [...inspection.pendingInspections];

      reports[index] = {
        ...reports[index],
        obs: value,
      };

      setInspection({
        ...inspection,
        pendingInspections: reports,
      });
    }
  }

  function addProduct() {
    const reports = [...form.reports];
    const pendingInspections = [...inspection.pendingInspections];

    reports.push({
      detailId,
      report,
      product,
      obs,
    });

    pendingInspections.splice(index, 1);

    setForm({
      ...form,
      reports: reports,
    });

    setInspection({
      ...inspection,
      pendingInspections: pendingInspections,
    });
  }

  async function removeProduct(params) {
    try {
      if (detailId) await deleteDetails(params);

      loadInspectionData();

      setRemoving(false);

      toastUtils.toaster({
        message: "Serviço cancelado com sucesso!",
        type: toastUtils.type.success,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    } catch (error) {
      toastUtils.toaster({
        message: error.response.data,
        type: toastUtils.type.error,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    }
  }

  return (
    <>
      <ProductSelectorWrapper theme={theme}>
        <div>
          {editing && (
            <>
              {list === "scheduled" ? (
                <FaMinusCircle onClick={() => setRemoving(true)} />
              ) : (
                <FaPlusCircle onClick={addProduct} />
              )}
            </>
          )}

          <h5>{`${product.name} - ${report.name}`}</h5>
        </div>

        <ProductComment
          theme={theme}
          type="text"
          placeholder="Observações..."
          value={obs || ""}
          onChange={(e) => handleComment(e.target.value)}
          disabled={!editing}
          editing={editing}
        />
      </ProductSelectorWrapper>

      <ConfirmationMessage
        text={
          form.reports.length > 1
            ? "Tem certeza que deseja cancelar o agendamento deste serviço?"
            : "Não é possível cancelar todos os serviços de um agendamento!"
        }
        action={removeProduct}
        confirming={removing}
        setConfirming={setRemoving}
        params={{
          inspectionId,
          det: detailId,
        }}
        okMessage={form.reports.length > 1 ? false : true}
      ></ConfirmationMessage>
    </>
  );
}
