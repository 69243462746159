import { IoMdPrint } from "react-icons/io";

import { Container } from "./styles";
import theme from "../../styles/theme";

export default function PrintButton() {
  return (
    <Container
      theme={theme}
      onClick={() => window.print()}
      className="no-print"
    >
      <IoMdPrint />
    </Container>
  );
}
