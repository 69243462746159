import { useEffect, useState } from "react";

import { Container } from "./styles";
import toastUtils from "../../utils/toast-utils";
import Loader from "../Loader";
import theme from "../../styles/theme";

export default function PdfViewer({ fileSource }) {
  const [file, setFile] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    try {
      setFile(fileSource);

      setLoading(false);
    } catch (error) {
      toastUtils.toaster({
        message: "Não foi possível localizar o arquivo!",
        type: toastUtils.type.error,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    }
  }, [fileSource]);

  return (
    <>
      {loading ? (
        <Loader theme={theme} />
      ) : (
        <Container theme={theme}>
          <iframe src={file}>
            Seu navegador não suporta visualização de PDF.
          </iframe>
        </Container>
      )}
    </>
  );
}
