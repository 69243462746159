import { IoDocumentAttachOutline } from "react-icons/io5";

import theme from "../../../../styles/theme";
import { Container } from "./styles";

export default function FileInput({ form, setForm, id, name, ...rest }) {
  function handleFileChange(e) {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setForm((prevForm) => ({
          ...prevForm,
          [id]: reader.result,
          [name]: file.name,
        }));
      };
      reader.readAsDataURL(file);
    }
  }

  return (
    <Container theme={theme} enabled={rest.enabled}>
      <label htmlFor={id}>
        <IoDocumentAttachOutline />

        <input
          id={id}
          name={name}
          type="file"
          accept=".pdf"
          disabled={!rest.enabled}
          onChange={handleFileChange}
          {...rest}
        />
      </label>

      <p>{form[name]}</p>
    </Container>
  );
}
