import { Section } from "../../../../components/Section";
import theme from "../../../../styles/theme";
import inspectionUtils from "../../../../utils/inspection-utils";
import { Container } from "../../styles";
import InspectionList from "../InspectionList";

export default function AssignedAnalyses() {
  return (
    <Section title="Certificados/Análises Documentais/Atribuídas">
      <Container theme={theme}>
        <InspectionList
          statusId={inspectionUtils.status.AGUARDANDO_ANALISE_VISTORIADOR}
          type={inspectionUtils.inspectionTypes.analysis}
        />
      </Container>
    </Section>
  );
}
