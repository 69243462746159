import { RiShutDownLine } from "react-icons/ri";
import { useLocation, useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { IoChevronBackCircle } from "react-icons/io5";
import { BiMenu } from "react-icons/bi";

import { Container, Profile, Logout, Back, MenuButton } from "./styles";
import theme from "../../styles/theme";
import useSignOut from "../../hooks/api/authentication/useLogOut";
import UserContext from "../../contexts/UserContext";
import EnrollmentContext from "../../contexts/EnrollmentContext";
import toastUtils from "../../utils/toast-utils";
import LocationsContext from "../../contexts/locationsContext";
import NotificationBell from "../NotificationBell";
import useNotifications from "../../hooks/api/notification/useNotifications";

export function Header({ displayMenu, setDisplayMenu }) {
  const path = useLocation().pathname;
  const navigate = useNavigate();
  const { userData, setUserData } = useContext(UserContext);
  const { setEnrollmentData } = useContext(EnrollmentContext);
  const { signOut } = useSignOut();
  const { setLocations } = useContext(LocationsContext);
  const { getNotifications, notificationsError } = useNotifications();
  const [count, setCount] = useState(0);
  const [timer, setTimer] = useState(0);

  useEffect(() => {
    loadNotifications();
  }, [path, timer]);

  setInterval(() => {
    setTimer(timer + 1);
  }, 30000);

  async function loadNotifications() {
    const params = {
      skip: 0,
      take: 10000000000,
      limit: new Date(),
      read: false,
    };

    if (!userData.permission || notificationsError) return;

    try {
      const response = await getNotifications(params);

      if (response) setCount(response.length);
    } catch {
      return;
    }
  }

  async function handleSignOut() {
    try {
      await signOut(userData.user.id);

      setLocations({});

      toastUtils.toaster({
        message: "Sessão encerrada!",
        type: toastUtils.type.default,
        position: toastUtils.position.topLeft,
        theme: { box: theme.COLORS.BLUE, progressBar: theme.COLORS.WHITE },
      });

      setUserData({});
      setEnrollmentData({});
      navigate("/");
    } catch (error) {
      toastUtils.toaster({
        message: error.response.data,
        type: toastUtils.type.error,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    }
  }

  return (
    <Container theme={theme} className="no-print">
      <span className="left">
        <Back
          disabled={path === "/painel/inicio"}
          theme={theme}
          onClick={() =>
            path !== "/painel/inicio"
              ? navigate(-1)
              : () => {
                  return;
                }
          }
        >
          <IoChevronBackCircle />
        </Back>

        <MenuButton
          theme={theme}
          displayMenu={displayMenu}
          onClick={() => setDisplayMenu(!displayMenu)}
        >
          <BiMenu />
        </MenuButton>
      </span>

      <Profile to={"/painel/inicio"} theme={theme}>
        <img
          src="https://salvage.com.br/wp-content/uploads/2022/08/logo-salvage.svg"
          alt="SALVAGE"
        />
        <div>
          <span> </span>
          <strong> </strong>
        </div>
      </Profile>

      <span className="right">
        <NotificationBell count={count} />

        <Logout theme={theme} onClick={handleSignOut}>
          <RiShutDownLine />
          <p>Sair</p>
        </Logout>
      </span>
    </Container>
  );
}
