import { useLocation, useParams } from "react-router-dom";
import { useEffect, useState } from "react";

import { Section } from "../../../../components/Section";
import Checklist from "../../../../components/forms/report-form/Checklist";
import theme from "../../../../styles/theme";
import { Container } from "./styles";
import inspectionUtils from "../../../../utils/inspection-utils";
import useInspectionData from "../../../../hooks/api/inspection/useInspectionData";
import useInspectionDetail from "../../../../hooks/api/inspection/useInspectionDetail";
import Loader from "../../../../components/Loader";
import toastUtils from "../../../../utils/toast-utils";
import useVessel from "../../../../hooks/api/vessel/useVessel";
import { Divider } from "../../Reports/styles";
import ReportInfo from "../../../../components/forms/report-form/ReportInfo";
import reportUtils from "../../../../utils/report-utils";

export default function FillChecklist() {
  const { inspectionId } = useParams();
  const searchParams = new URLSearchParams(useLocation().search);
  const formId = searchParams.get("formId");
  const type = searchParams.get("type");
  const enabled = true;
  const { getInspectionData, inspectionDataLoading } = useInspectionData();
  const { getInspectionDetail, inspectionDetailLoading } =
    useInspectionDetail();
  const { getVessel, vesselLoading } = useVessel();
  const [checklist, setChecklist] = useState(reportUtils.emptyChecklist);
  const [title, setTitle] = useState(
    type === inspectionUtils.inspectionTypes.inspection
      ? "Certificados/Vistorias/Agendadas"
      : "Certificados/Análises Documentais/Atribuídas"
  );
  const [inspection, setInspection] = useState(
    inspectionUtils.emptyInspectionData
  );
  const [inspectionDate, setInspectionDate] = useState({ date: new Date() });
  const [inspectionInfo, setInspectionInfo] = useState([]);
  const [vessel, setVessel] = useState([]);
  const [answers, setAnswers] = useState({});
  const [summary, setSummary] = useState({});
  const [stakeholders, setStakeholders] = useState({
    coordinatorId: 0,
    inspectorId: 0,
  });

  useEffect(() => {
    loadInspectionData();
  }, []);

  useEffect(() => {
    if (inspection.vessel.id) loadVesselData();
  }, [inspection]);

  useEffect(() => {
    if (checklist.product.id && inspection.id) loadInspectionDetail();
  }, [checklist, inspection]);

  async function loadInspectionData() {
    try {
      const response = await getInspectionData(inspectionId);

      setInspection(response);

      const info = [
        { label: "Ordem de serviço", value: response.request.os },
        { label: "Status", value: response.status.name },
        { label: "Coordenador", value: response.coordinator.name },
      ];

      setInspectionInfo(info);

      setTitle(
        `${title}/${response.id} - ${response.vessel.name} (${response.company.name})`
      );
    } catch (error) {
      toastUtils.toaster({
        message: error.response.data,
        type: toastUtils.type.error,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    }
  }

  async function loadInspectionDetail() {
    try {
      const response = await getInspectionDetail({
        inspectionId,
        formId: checklist.id,
      });

      setInspectionDate({
        ...inspectionDate,
        date: response.date,
      });

      setStakeholders({
        coordinatorId: inspection.coordinator.id,
        inspectorId: response.inspector.id,
      });

      if (inspectionInfo.find((item) => item.label === "Vistoriador")) return;

      setInspectionInfo([
        ...inspectionInfo,
        { label: "Vistoriador", value: response.inspector.name },
      ]);
    } catch (error) {
      toastUtils.toaster({
        message: error.response.data,
        type: toastUtils.type.error,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    }
  }

  async function loadVesselData() {
    try {
      const response = await getVessel(inspection.vessel.id);

      if (response) {
        const data = [
          {
            label: "Nome da Embarcação/Nº Casco",
            value: `${response.vessel.name}/${
              response.vessel.registration || ""
            }`,
          },
          {
            label: "Área de Navegação",
            value: response.vessel.navigationType.label,
          },
          {
            label: "Tipo da Embarcação",
            value: response.vessel.vesselType.label,
          },
          {
            label: "Arqueação Bruta",
            value: response.vessel.grossTonnage || "Não informado",
          },
        ];

        setVessel(data);
      }
    } catch (error) {
      toastUtils.toaster({
        message: error.response.data,
        type: toastUtils.type.error,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    }
  }

  return (
    <Section title={title}>
      <Container theme={theme}>
        {inspectionDataLoading ? (
          <Loader theme={theme} />
        ) : (
          <>
            {vesselLoading ? (
              <Loader theme={theme} />
            ) : (
              <InfoSection
                info={vessel}
                title="Dados do Contrato de Construção"
              />
            )}

            <Divider theme={theme} />

            {inspectionDetailLoading ? (
              <Loader theme={theme} />
            ) : (
              <InfoSection
                info={inspectionInfo}
                title={`Dados da ${
                  type === inspectionUtils.inspectionTypes.inspection
                    ? "Vistoria"
                    : "Análise"
                }`}
              />
            )}

            <Divider theme={theme} />

            <Checklist
              formId={formId}
              enabled={enabled}
              title={title}
              setTitle={setTitle}
              checklist={checklist}
              setChecklist={setChecklist}
              inspectionId={inspectionId}
              inspectionDate={inspectionDate}
              setInspectionDate={setInspectionDate}
              answers={answers}
              setAnswers={setAnswers}
              summary={summary}
              setSummary={setSummary}
              stakeholders={stakeholders}
            />
          </>
        )}
      </Container>
    </Section>
  );
}

function InfoSection({ info, title }) {
  return (
    <div>
      <h3>{title}</h3>

      <ReportInfo info={info} />
    </div>
  );
}
