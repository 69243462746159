import styled from "styled-components";

export const Container = styled.div`
  width: 40px !important;
  height: 40px;
  border-radius: 50%;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.3);
  background-color: ${({ theme }) => theme.COLORS.ORANGE};
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -10px;
  right: 70px;
  margin-right: ${({ margin }) => margin || 0};
  cursor: pointer;

  svg {
    color: ${({ theme }) => theme.COLORS.WHITE};
    width: 60%;
    height: 60%;
  }
`;
