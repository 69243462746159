import useAsync from "../../useAsync";

import * as notificationApi from "../../../services/notificationApi";
import useToken from "../../useToken";

export default function useDeleteAllNotifications() {
  const token = useToken();

  const {
    loading: deleteAllNotificationsLoading,
    error: deleteAllNotificationsError,
    act: deleteAllNotifications,
  } = useAsync(() => notificationApi.deleteAllNotifications(token), false);

  return {
    deleteAllNotificationsLoading,
    deleteAllNotificationsError,
    deleteAllNotifications,
  };
}
