function findKeyById(object, value) {
  for (let key in object) {
    if (object[key] === value) {
      return key;
    }
  }
  return null;
}

function removeSpecialChar(text) {
  if (!text) return null;

  text = text.toLowerCase();

  const accentHexMap = {
    a: /[\xE0-\xE6]/g,
    e: /[\xE8-\xEB]/g,
    i: /[\xEC-\xEF]/g,
    o: /[\xF2-\xF6]/g,
    u: /[\xF9-\xFC]/g,
    c: /\xE7/g,
    n: /\xF1/g,
  };

  for (let letter in accentHexMap) {
    const regexp = accentHexMap[letter];
    text = text.replace(regexp, letter);
  }

  return text;
}

function precisionMask(value) {
  const number = Number(value.replace(",", "").replace(/\./g, "")) / 100;

  let result = number ? number.toLocaleString("pt-BR") : "0,00";

  if (result.indexOf(",") !== result.length - 3 && result.includes(","))
    result += "0";

  if (!result.includes(",")) result += ",00";

  return result;
}

function handleDirSearch({ search, directories, setDirList }) {
  if (!search) return setDirList(directories);

  const result = directories.filter((item) =>
    item.text.toLowerCase().includes(search.toLowerCase())
  );

  setDirList(result);
}

const urls = {
  website: "https://www.",
  instagram: "https://www.instagram.com/",
  linkedin: "https://www.linkedin.com/in/",
};

function getDatesInterval(startDate, endDate) {
  const dates = [];

  if (!startDate || !endDate) return [];

  let currentDate = new Date(startDate);

  while (currentDate <= new Date(endDate)) {
    dates.push(new Date(currentDate.setHours(0, 0, 0, 0)));
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return dates;
}

function generateDateHashtable(dates) {
  let hashtable = {};

  dates.forEach((date) => {
    hashtable[date.getTime()] = true;
  });

  return hashtable;
}

const nationalitiesConvertion = {
  AFG: "Afegã",
  ALB: "Albanesa",
  DZA: "Argelina",
  AND: "Andorrana",
  AGO: "Angolana",
  ATG: "Antiguana",
  ARG: "Argentina",
  ARM: "Armênia",
  AUS: "Australiana",
  AUT: "Austríaca",
  AZE: "Azeri",
  BHS: "Bahamense",
  BHR: "Bahrenita",
  BGD: "Bangladeshiana",
  BRB: "Barbadiana",
  BLR: "Bielorrussa",
  BEL: "Belga",
  BLZ: "Belizenha",
  BEN: "Beninense",
  BTN: "Butanesa",
  BOL: "Boliviana",
  BIH: "Bósnia",
  BWA: "Botsuanesa",
  BRA: "Brasileira",
  BRN: "Bruneiana",
  BGR: "Búlgara",
  BFA: "Burquinense",
  BDI: "Burundiana",
  CPV: "Cabo-Verdiana",
  KHM: "Cambojana",
  CMR: "Camaronesa",
  CAN: "Canadense",
  CAF: "Centro-Africana",
  TCD: "Chadiana",
  CHL: "Chilena",
  CHN: "Chinesa",
  COL: "Colombiana",
  COM: "Comorense",
  COG: "Congolesa (República do Congo)",
  COD: "Congolesa (RDC)",
  CRI: "Costarriquenha",
  HRV: "Croata",
  CUB: "Cubana",
  CYP: "Cipriota",
  CZE: "Tcheca",
  DNK: "Dinamarquesa",
  DJI: "Jibutiana",
  DMA: "Dominiquense",
  DOM: "Dominicana",
  ECU: "Equatoriana",
  EGY: "Egípcia",
  SLV: "Salvadorenha",
  GNQ: "Guinéu-equatoriana",
  ERI: "Eritreia",
  EST: "Estoniana",
  SWZ: "Suazi",
  ETH: "Etíope",
  FJI: "Fijiana",
  FIN: "Finlandesa",
  FRA: "Francesa",
  GAB: "Gabonesa",
  GMB: "Gambiana",
  GEO: "Georgiana",
  DEU: "Alemã",
  GHA: "Ganense",
  GRC: "Grega",
  GRD: "Granadina",
  GTM: "Guatemalteca",
  GIN: "Guineense",
  GNB: "Guiné-Bissauense",
  GUY: "Guyanesa",
  HTI: "Haitiana",
  HND: "Hondurenha",
  HUN: "Húngara",
  ISL: "Islandesa",
  IND: "Indiana",
  IDN: "Indonésia",
  IRN: "Iraniana",
  IRQ: "Iraquiana",
  IRL: "Irlandesa",
  ISR: "Israelense",
  ITA: "Italiana",
  JAM: "Jamaicana",
  JPN: "Japonesa",
  JOR: "Jordana",
  KAZ: "Cazaque",
  KEN: "Queniana",
  KIR: "Quiribatiana",
  KWT: "Kuwaitiana",
  KGZ: "Quirguiz",
  LAO: "Laosiana",
  LVA: "Letã",
  LBN: "Libanesa",
  LSO: "Lesotiana",
  LBR: "Liberiana",
  LBY: "Líbia",
  LIE: "Liechtensteiniana",
  LTU: "Lituana",
  LUX: "Luxemburguesa",
  MDG: "Malgaxe",
  MWI: "Malauiana",
  MYS: "Malásia",
  MDV: "Maldívia",
  MLI: "Malinesa",
  MLT: "Maltesa",
  MHL: "Marshallina",
  MRT: "Mauritana",
  MUS: "Mauriciana",
  MEX: "Mexicana",
  FSM: "Micronésia",
  MDA: "Moldava",
  MCO: "Monegasca",
  MNG: "Mongol",
  MNE: "Montenegrina",
  MAR: "Marroquina",
  MOZ: "Moçambicana",
  MMR: "Birmanesa",
  NAM: "Namibiana",
  NRU: "Nauruana",
  NPL: "Nepalesa",
  NLD: "Holandesa",
  NZL: "Neozelandesa",
  NIC: "Nicaraguense",
  NER: "Nigerina",
  NGA: "Nigeriana",
  PRK: "Norte-Coreana",
  MKD: "Macedônia do Norte",
  NOR: "Norueguesa",
  OMN: "Omanense",
  PAK: "Paquistanesa",
  PLW: "Palauana",
  PAN: "Panamenha",
  PNG: "Papua-Nova Guiné",
  PRY: "Paraguaia",
  PER: "Peruana",
  PHL: "Filipina",
  POL: "Polonesa",
  PRT: "Portuguesa",
  QAT: "Qatarense",
  ROU: "Romena",
  RUS: "Russa",
  RWA: "Ruandesa",
  KNA: "São-Cristovense",
  LCA: "Santa-Lucense",
  VCT: "São-vincentense",
  SMR: "Sanmarinense",
  STP: "São-Tomense",
  SAU: "Saudita",
  SEN: "Senegalesa",
  SRB: "Sérvia",
  SYC: "Seichelense",
  SLE: "Serraleonense",
  SGP: "Singapurense",
  SVK: "Eslovaca",
  SVN: "Eslovena",
  SLB: "Salomônica",
  SOM: "Somali",
  ZAF: "Sul-Africana",
  KOR: "Sul-Coreana",
  SSD: "Sul-Sudanesa",
  ESP: "Espanhola",
  LKA: "Cingalesa",
  SDN: "Sudanesa",
  SUR: "Surinamesa",
  SWE: "Sueca",
  CHE: "Suíça",
  SYR: "Síria",
  TJK: "Tajique",
  TZA: "Tanzaniana",
  THA: "Tailandesa",
  TLS: "Timorense",
  TGO: "Togolesa",
  TON: "Tonganesa",
  TTO: "Trindadense",
  TUN: "Tunisiana",
  TUR: "Turca",
  TKM: "Turcomena",
  TUV: "Tuvaluana",
  UGA: "Ugandense",
  UKR: "Ucraniana",
  ARE: "Emiradense",
  GBR: "Britânica",
  USA: "Americana",
  URY: "Uruguaia",
  UZB: "Uzbeque",
  VUT: "Vanuatuense",
  VAT: "Vaticana",
  VEN: "Venezuelana",
  VNM: "Vietnamita",
  YEM: "Iemenita",
  ZMB: "Zambiana",
  ZWE: "Zimbabuense",
};

const nationalitiesList = [
  { label: "AFG", value: "AFG" },
  { label: "ALB", value: "ALB" },
  { label: "DZA", value: "DZA" },
  { label: "AND", value: "AND" },
  { label: "AGO", value: "AGO" },
  { label: "ATG", value: "ATG" },
  { label: "ARG", value: "ARG" },
  { label: "ARM", value: "ARM" },
  { label: "AUS", value: "AUS" },
  { label: "AUT", value: "AUT" },
  { label: "AZE", value: "AZE" },
  { label: "BHS", value: "BHS" },
  { label: "BHR", value: "BHR" },
  { label: "BGD", value: "BGD" },
  { label: "BRB", value: "BRB" },
  { label: "BLR", value: "BLR" },
  { label: "BEL", value: "BEL" },
  { label: "BLZ", value: "BLZ" },
  { label: "BEN", value: "BEN" },
  { label: "BTN", value: "BTN" },
  { label: "BOL", value: "BOL" },
  { label: "BIH", value: "BIH" },
  { label: "BWA", value: "BWA" },
  { label: "BRA", value: "BRA" },
  { label: "BRN", value: "BRN" },
  { label: "BGR", value: "BGR" },
  { label: "BFA", value: "BFA" },
  { label: "BDI", value: "BDI" },
  { label: "CPV", value: "CPV" },
  { label: "KHM", value: "KHM" },
  { label: "CMR", value: "CMR" },
  { label: "CAN", value: "CAN" },
  { label: "CAF", value: "CAF" },
  { label: "TCD", value: "TCD" },
  { label: "CHL", value: "CHL" },
  { label: "CHN", value: "CHN" },
  { label: "COL", value: "COL" },
  { label: "COM", value: "COM" },
  { label: "COG", value: "COG" },
  { label: "COD", value: "COD" },
  { label: "CRI", value: "CRI" },
  { label: "HRV", value: "HRV" },
  { label: "CUB", value: "CUB" },
  { label: "CYP", value: "CYP" },
  { label: "CZE", value: "CZE" },
  { label: "DNK", value: "DNK" },
  { label: "DJI", value: "DJI" },
  { label: "DMA", value: "DMA" },
  { label: "DOM", value: "DOM" },
  { label: "ECU", value: "ECU" },
  { label: "EGY", value: "EGY" },
  { label: "SLV", value: "SLV" },
  { label: "GNQ", value: "GNQ" },
  { label: "ERI", value: "ERI" },
  { label: "EST", value: "EST" },
  { label: "SWZ", value: "SWZ" },
  { label: "ETH", value: "ETH" },
  { label: "FJI", value: "FJI" },
  { label: "FIN", value: "FIN" },
  { label: "FRA", value: "FRA" },
  { label: "GAB", value: "GAB" },
  { label: "GMB", value: "GMB" },
  { label: "GEO", value: "GEO" },
  { label: "DEU", value: "DEU" },
  { label: "GHA", value: "GHA" },
  { label: "GRC", value: "GRC" },
  { label: "GRD", value: "GRD" },
  { label: "GTM", value: "GTM" },
  { label: "GIN", value: "GIN" },
  { label: "GNB", value: "GNB" },
  { label: "GUY", value: "GUY" },
  { label: "HTI", value: "HTI" },
  { label: "HND", value: "HND" },
  { label: "HUN", value: "HUN" },
  { label: "ISL", value: "ISL" },
  { label: "IND", value: "IND" },
  { label: "IDN", value: "IDN" },
  { label: "IRN", value: "IRN" },
  { label: "IRQ", value: "IRQ" },
  { label: "IRL", value: "IRL" },
  { label: "ISR", value: "ISR" },
  { label: "ITA", value: "ITA" },
  { label: "JAM", value: "JAM" },
  { label: "JPN", value: "JPN" },
  { label: "JOR", value: "JOR" },
  { label: "KAZ", value: "KAZ" },
  { label: "KEN", value: "KEN" },
  { label: "KIR", value: "KIR" },
  { label: "KWT", value: "KWT" },
  { label: "KGZ", value: "KGZ" },
  { label: "LAO", value: "LAO" },
  { label: "LVA", value: "LVA" },
  { label: "LBN", value: "LBN" },
  { label: "LSO", value: "LSO" },
  { label: "LBR", value: "LBR" },
  { label: "LBY", value: "LBY" },
  { label: "LIE", value: "LIE" },
  { label: "LTU", value: "LTU" },
  { label: "LUX", value: "LUX" },
  { label: "MDG", value: "MDG" },
  { label: "MWI", value: "MWI" },
  { label: "MYS", value: "MYS" },
  { label: "MDV", value: "MDV" },
  { label: "MLI", value: "MLI" },
  { label: "MLT", value: "MLT" },
  { label: "MHL", value: "MHL" },
  { label: "MRT", value: "MRT" },
  { label: "MUS", value: "MUS" },
  { label: "MEX", value: "MEX" },
  { label: "FSM", value: "FSM" },
  { label: "MDA", value: "MDA" },
  { label: "MCO", value: "MCO" },
  { label: "MNG", value: "MNG" },
  { label: "MNE", value: "MNE" },
  { label: "MAR", value: "MAR" },
  { label: "MOZ", value: "MOZ" },
  { label: "MMR", value: "MMR" },
  { label: "NAM", value: "NAM" },
  { label: "NRU", value: "NRU" },
  { label: "NPL", value: "NPL" },
  { label: "NLD", value: "NLD" },
  { label: "NZL", value: "NZL" },
  { label: "NIC", value: "NIC" },
  { label: "NER", value: "NER" },
  { label: "NGA", value: "NGA" },
  { label: "PRK", value: "PRK" },
  { label: "MKD", value: "MKD" },
  { label: "NOR", value: "NOR" },
  { label: "OMN", value: "OMN" },
  { label: "PAK", value: "PAK" },
  { label: "PLW", value: "PLW" },
  { label: "PAN", value: "PAN" },
  { label: "PNG", value: "PNG" },
  { label: "PRY", value: "PRY" },
  { label: "PER", value: "PER" },
  { label: "PHL", value: "PHL" },
  { label: "POL", value: "POL" },
  { label: "PRT", value: "PRT" },
  { label: "QAT", value: "QAT" },
  { label: "ROU", value: "ROU" },
  { label: "RUS", value: "RUS" },
  { label: "RWA", value: "RWA" },
  { label: "KNA", value: "KNA" },
  { label: "LCA", value: "LCA" },
  { label: "VCT", value: "VCT" },
  { label: "SMR", value: "SMR" },
  { label: "STP", value: "STP" },
  { label: "SAU", value: "SAU" },
  { label: "SEN", value: "SEN" },
  { label: "SRB", value: "SRB" },
  { label: "SYC", value: "SYC" },
  { label: "SLE", value: "SLE" },
  { label: "SGP", value: "SGP" },
  { label: "SVK", value: "SVK" },
  { label: "SVN", value: "SVN" },
  { label: "SLB", value: "SLB" },
  { label: "SOM", value: "SOM" },
  { label: "ZAF", value: "ZAF" },
  { label: "KOR", value: "KOR" },
  { label: "SSD", value: "SSD" },
  { label: "ESP", value: "ESP" },
  { label: "LKA", value: "LKA" },
  { label: "SDN", value: "SDN" },
  { label: "SUR", value: "SUR" },
  { label: "SWE", value: "SWE" },
  { label: "CHE", value: "CHE" },
  { label: "SYR", value: "SYR" },
  { label: "TJK", value: "TJK" },
  { label: "TZA", value: "TZA" },
  { label: "THA", value: "THA" },
  { label: "TLS", value: "TLS" },
  { label: "TGO", value: "TGO" },
  { label: "TON", value: "TON" },
  { label: "TTO", value: "TTO" },
  { label: "TUN", value: "TUN" },
  { label: "TUR", value: "TUR" },
  { label: "TKM", value: "TKM" },
  { label: "TUV", value: "TUV" },
  { label: "UGA", value: "UGA" },
  { label: "UKR", value: "UKR" },
  { label: "ARE", value: "ARE" },
  { label: "GBR", value: "GBR" },
  { label: "USA", value: "USA" },
  { label: "URY", value: "URY" },
  { label: "UZB", value: "UZB" },
  { label: "VUT", value: "VUT" },
  { label: "VAT", value: "VAT" },
  { label: "VEN", value: "VEN" },
  { label: "VNM", value: "VNM" },
  { label: "YEM", value: "YEM" },
  { label: "ZMB", value: "ZMB" },
  { label: "ZWE", value: "ZWE" },
];

const generalUtils = {
  findKeyById,
  removeSpecialChar,
  precisionMask,
  handleDirSearch,
  urls,
  getDatesInterval,
  generateDateHashtable,
  nationalitiesConvertion,
  nationalitiesList,
};

export default generalUtils;
