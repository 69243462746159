import useAsync from "../../useAsync";
import * as inspectionApi from "../../../services/inspectionApi";
import useToken from "../../useToken";

export default function useVerificationTypes() {
  const token = useToken();

  const {
    data: verificationTypes,
    loading: verificationTypesLoading,
    error: verificationTypesError,
    act: getVerificationTypes,
  } = useAsync(() => inspectionApi.getVerificationTypes(token), false);

  return {
    verificationTypes,
    verificationTypesLoading,
    verificationTypesError,
    getVerificationTypes,
  };
}
