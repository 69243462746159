import { Chrono } from "react-chrono";
import { BsFiletypePdf } from "react-icons/bs";
import dayjs from "dayjs";
import "dayjs/locale/pt-br";

import { Container, ItemWrapper } from "./styles";
import { useEffect, useState } from "react";
import theme from "../../../../styles/theme";
import useRequestHistory from "../../../../hooks/api/request/useRequestHistory";
import toastUtils from "../../../../utils/toast-utils";
import serviceUtils from "../../../../utils/service-utils";
import noPicProfile from "../../../../assets/no-pic-profile.png";
import { useNavigate } from "react-router-dom";

export default function Timeline({ serviceId, status }) {
  const [items, setItems] = useState([]);
  const [timelineItems, setTimelineItems] = useState([]);
  const { getRequestHistory } = useRequestHistory();

  const timelineTheme = {
    primary: theme.COLORS.GRAY_100,
    secondary: theme.COLORS.ORANGE,
    cardBgColor: theme.COLORS.BACKGROUND_700,
    titleColor: theme.COLORS.GRAY_100,
    titleColorActive: theme.COLORS.BACKGROUND_700,
  };

  useEffect(() => {
    loadItems();
  }, [status]);

  async function loadItems() {
    try {
      const response = await getRequestHistory(serviceId);

      setTimelineItems(response);

      if (response) {
        const timelineItems = response.map((request) => {
          const title = dayjs(request.createdAt)
            .locale("pt-br")
            .format("dddd, DD/MM/YYYY (HH:mm)");
          return {
            title: title.charAt(0).toUpperCase() + title.slice(1),
          };
        });

        setItems(timelineItems);
      }
    } catch (error) {
      toastUtils.toaster({
        message: error.response.data,
        type: toastUtils.type.error,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    }
  }

  return (
    <Container theme={theme}>
      <h3>Hitórico da Solicitação</h3>
      <Chrono
        items={items}
        mode="VERTICAL"
        theme={timelineTheme}
        disableToolbar
        allowDynamicUpdate={true}
        scrollable={false}
        hideControls={false}
        activeItemIndex={items.length - 1}
      >
        {timelineItems.map((request, index) => (
          <TimelineItem item={request} key={index} />
        ))}
      </Chrono>
    </Container>
  );
}

function TimelineItem({ item }) {
  const navigate = useNavigate();
  const title = dayjs(item.createdAt)
    .locale("pt-br")
    .format("dddd, DD/MM/YYYY (HH:mm)");

  function handleButtonClick(e) {
    e.preventDefault();
    e.stopPropagation();
    navigate(`/painel/servicos/os/${item.id}?status=${item.status.id}`, {
      state: {
        fileSource: item.serviceOrder.file,
        fileName: item.serviceOrder.name,
      },
    });
  }

  return (
    <ItemWrapper theme={theme} selected={item.selected} className="item">
      <h1>{item.status.name}</h1>
      <h5>{title.charAt(0).toUpperCase() + title.slice(1)}</h5>

      <h2>
        {item.status.id === serviceUtils.status.SOLICITADO
          ? "Solicitante:"
          : "Responsável:"}
      </h2>

      <div className="user">
        <img src={item.user.avatar || noPicProfile} alt="usuário" />

        <span>
          <h6>{item.user.name}</h6>
          <p>{item.user.role}</p>
        </span>
      </div>

      {item.status.id === serviceUtils.status.FINALIZADO && (
        <>
          <h2>Ordem de Serviço:</h2>
          <div className="os">
            <h6>{`Código: ${item.serviceOrder.code}`}</h6>
            <span onClick={handleButtonClick}>
              <BsFiletypePdf />
              <p>{item.serviceOrder.name}</p>
            </span>
          </div>
        </>
      )}
    </ItemWrapper>
  );
}
