import useAsync from "../../useAsync";

import * as dpcApi from "../../../services/dpcApi";
import useToken from "../../useToken";

export default function useDpcCertificates() {
  const token = useToken();

  const {
    data: dpcCertificates,
    loading: dpcCertificatesLoading,
    error: dpcCertificatesError,
    act: getDpcCertificates,
  } = useAsync((data) => dpcApi.getCertificates(data, token), false);

  return {
    dpcCertificates,
    dpcCertificatesLoading,
    dpcCertificatesError,
    getDpcCertificates,
  };
}
