import useAsync from "../../useAsync";

import * as dpcApi from "../../../services/dpcApi";
import useToken from "../../useToken";

export default function useDpcCertificateFilters() {
  const token = useToken();

  const {
    data: dpcCertificateFilters,
    loading: dpcCertificateFiltersLoading,
    error: dpcCertificateFiltersError,
    act: getDpcCertificateFilters,
  } = useAsync(() => dpcApi.getCertificateFilters(token), false);

  return {
    dpcCertificateFilters,
    dpcCertificateFiltersLoading,
    dpcCertificateFiltersError,
    getDpcCertificateFilters,
  };
}
