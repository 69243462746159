import styled from "styled-components";

export const Container = styled.section`
  margin: 28px 0;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;

  h2 {
    color: ${({ theme }) => theme.COLORS.GRAY_100};
    font-size: 26px;
    font-weight: 400;
    cursor: pointer;
    margin: 0;

    &:hover {
      color: ${({ theme }) => theme.COLORS.WHITE};
    }
  }

  /* h2:last-child {
    cursor: default;

    &:hover {
      color: ${({ theme }) => theme.COLORS.GRAY_100};
    }
  } */

  @media (max-width: 1023px) and (min-width: 768px) {
    min-width: 708px;
  }

  @media (max-width: 767px) {
    min-width: 300px;
  }

  @media print {
    margin: 0;
  }
`;

export const Title = styled.div`
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: left;
  border-bottom: 1px solid ${({ theme }) => theme.COLORS.BACKGROUND_700};
  margin: 30px 0;
  padding: 10px 0;
  flex-wrap: wrap;

  span:nth-child(2n) {
    padding-top: 10px;
  }

  span:last-child {
    max-width: 60%;
  }

  svg {
    width: 18px;
    height: 18px;
    margin: 0 8px;
    color: ${({ theme }) => theme.COLORS.GRAY_100};
  }

  h2 {
    white-space: nowrap;
    overflow: hidden;
    max-width: 100%;
    text-overflow: ellipsis;
  }

  @media (max-width: 1023px) and (min-width: 768px) {
    h2 {
      font-size: 22px;
    }

    svg {
      width: 14px;
      height: 14px;
      margin: 0 8px;
    }

    span:nth-child(2n) {
      padding-top: 4px;
    }

    span:last-child {
      max-width: 50%;
    }
  }

  @media (max-width: 767px) {
    h2 {
      font-size: 18px;
    }

    svg {
      width: 12px;
      height: 12px;
      margin: 0 8px;
    }

    span:nth-child(2n) {
      padding-top: 0;
    }
  }
`;
