import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { CgReorder } from "react-icons/cg";
import { BiFilter } from "react-icons/bi";
import { IoChevronForward } from "react-icons/io5";

import { Section } from "../../../components/Section";
import theme from "../../../styles/theme";
import { Container, ListItem } from "../styles";
import serviceUtils from "../../../utils/service-utils";
import useRequestsByStatus from "../../../hooks/api/request/useRequestsByStatus";
import useServiceFilters from "../../../hooks/api/service/useServiceFilters";
import toastUtils from "../../../utils/toast-utils";
import Select from "../../../components/forms/service-form/Select";
import FilterSelector from "../../../components/FilterSelector";
import { ParamsWrapper } from "../Enrollment/styles";
import { ServiceListItem } from "./styles";
import Loader from "../../../components/Loader";
import ListWrapper from "../../../components/ListWrapper";

export default function AuthorizedServices() {
  const responseLength = 20;
  const { getRequestsByStatus, requestsByStatusLoading } =
    useRequestsByStatus();
  const { getServiceFilters } = useServiceFilters();
  const [services, setServices] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const [filters, setFilters] = useState(serviceUtils.serviceEmptyFilters);
  const [displayParams, setDisplayParams] = useState(
    serviceUtils.serviceEmptyDisplayParams
  );
  const search = useLocation().search.replace("?search=", "");
  const [displayFilters, setDisplayFilters] = useState(false);

  useEffect(() => {
    loadServices();
    loadFilters();
  }, []);

  useEffect(() => {
    orderServices();
  }, [displayParams.order]);

  useEffect(() => {
    handleFilter();
    handleServiceSearch();
  }, [search, services, displayParams.company, displayParams.vessel]);

  function handleServiceSearch() {
    if (!search) return handleFilter();

    const newList = services.filter(
      (service) =>
        service.id === Number(search) ||
        service.company.name.toLowerCase().includes(search.toLowerCase()) ||
        service.vessel.name.toLowerCase().includes(search.toLowerCase()) ||
        service.os.toLowerCase().includes(search.toLowerCase()) ||
        service.osFile.toLowerCase().includes(search.toLowerCase())
    );

    setFilteredList(newList);
  }

  function handleForm({ name, value, form, setForm }) {
    setForm({ ...form, [name]: value });
  }

  function handleFilter() {
    let result = services;

    if (displayParams.company.value)
      result = result.filter(
        (service) => service.companyId === displayParams.company.value
      );

    if (displayParams.vessel.value)
      result = result.filter(
        (service) => service.vesselId === displayParams.vessel.value
      );

    if (displayParams.status.value)
      result = result.filter(
        (service) => service.statusId === displayParams.status.value
      );

    setFilteredList(result);
  }

  async function loadServices() {
    const skip = services.length;
    const take = responseLength;

    try {
      const response = await getRequestsByStatus({
        order: displayParams.order.value ? displayParams.order.value : "",
        skip,
        take,
        statusId: serviceUtils.status.FINALIZADO,
      });

      if (response) {
        setServices(services.concat(response));
        setFilteredList(services.concat(response));
      }
    } catch (error) {
      toastUtils.toaster({
        message: error.response.data,
        type: toastUtils.type.error,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    }
  }

  async function loadFilters() {
    try {
      const response = await getServiceFilters(
        serviceUtils.types.SERVIÇO_AUTORIZADO
      );

      setFilters({
        ...filters,
        companies: filters.companies.concat(response.companies),
        vessels: filters.vessels.concat(response.vessels),
      });
    } catch (error) {
      toastUtils.toaster({
        message: "Não foi possível carregar os filtros!",
        type: toastUtils.type.error,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    }
  }

  async function orderServices() {
    const skip = 0;
    const take = responseLength;

    try {
      const response = await getRequestsByStatus({
        order: displayParams.order.value ? displayParams.order.value : "",
        skip,
        take,
        statusId: serviceUtils.status.FINALIZADO,
      });

      if (response) {
        setServices(response);
        setFilteredList(response);
      }
    } catch (error) {
      toastUtils.toaster({
        message: error.response.data,
        type: toastUtils.type.error,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    }
  }

  return (
    <Section title="Serviços/Ordens de Serviço">
      <Container theme={theme}>
        <ParamsWrapper displayFilters={displayFilters}>
          <Select
            id="order"
            icon={CgReorder}
            placeholder={"Ordenar por..."}
            enabled={true}
            list={serviceUtils.serviceOrderList}
            selected={displayParams.order}
            form={displayParams}
            setForm={setDisplayParams}
            handleForm={handleForm}
          />

          <FilterSelector
            displayFilters={displayFilters}
            setDisplayFilters={setDisplayFilters}
          />

          <Select
            id="company"
            icon={BiFilter}
            placeholder={"Empresa"}
            enabled={true}
            list={filters.companies}
            selected={displayParams.company}
            form={displayParams}
            setForm={setDisplayParams}
            handleForm={handleForm}
            className="filter"
          />

          <Select
            id="vessel"
            icon={BiFilter}
            placeholder={"Embarcação"}
            enabled={true}
            list={
              displayParams.company.value
                ? serviceUtils.serviceEmptyFilters.vessels.concat(
                    filters.vessels.filter(
                      (vessel) =>
                        vessel.companyId === displayParams.company.value
                    )
                  )
                : filters.vessels
            }
            selected={displayParams.vessel}
            form={displayParams}
            setForm={setDisplayParams}
            handleForm={handleForm}
            className="filter"
          />
        </ParamsWrapper>

        {requestsByStatusLoading ? (
          <Loader />
        ) : (
          <ListWrapper
            offset={responseLength}
            list={services}
            loadList={loadServices}
            loading={requestsByStatusLoading}
          >
            {filteredList.map((service, index) => (
              <ServiceWrapper
                key={index}
                id={service.id}
                osFile={service.osFile}
                osFileName={service.osFileName}
                status={service.statusId}
              />
            ))}
          </ListWrapper>
        )}
      </Container>
    </Section>
  );
}

function ServiceWrapper({ id, osFile, osFileName, status }) {
  const navigate = useNavigate();

  return (
    <ListItem
      theme={theme}
      onClick={() =>
        navigate(`${id}?status=${status}`, {
          state: { fileSource: osFile, fileName: osFileName },
        })
      }
    >
      <ServiceListItem>
        <h5>{osFileName}</h5>

        <IoChevronForward />
      </ServiceListItem>
    </ListItem>
  );
}
