import styled from "styled-components";

import homeUtils from "../../../utils/home-utils";

export const Container = styled.div`
  width: 300px;
  height: 40px;
  position: absolute;
  top: 120px;
  right: 5%;
  padding: 44px 10% 0;
  display: ${({ path }) =>
    homeUtils.displaySearchBar(path) ? "flex" : "none"};
  align-items: center;
  background-color: ${({ theme }) => theme.COLORS.BACKGROUND_900};
  color: ${({ theme }) => theme.COLORS.GRAY_300};
  margin-bottom: 8px;
  border-radius: 20px;
  border: 1px solid ${({ theme }) => theme.COLORS.BACKGROUND_700};
  z-index: 1;
  padding: 0;
  transition: width 500ms;
  -webkit-transition: width 500ms;

  &:focus-within {
    width: 500px;
  }

  form {
    width: 100%;
  }

  input {
    width: 100%;
    padding: 10px;
    color: ${({ theme }) => theme.COLORS.WHITE};
    background: transparent;
    border: 0;
    font-size: 16px;

    &:placeholder {
      color: ${({ theme }) => theme.COLORS.GRAY_300};
    }

    &:focus {
      box-shadow: 0 0 0 0;
      border: 0 none;
      outline: 0;
    }

    &:autofill {
      box-shadow: 0 0 0 30px ${({ theme }) => theme.COLORS.BACKGROUND_900} inset;
      color: ${({ theme }) => theme.COLORS.WHITE}!important;
      border-radius: 0 20px 20px 0;
      background: transparent;
    }

    &:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 30px
        ${({ theme }) => theme.COLORS.BACKGROUND_900} inset;
      -webkit-text-fill-color: ${({ theme }) => theme.COLORS.WHITE}!important;
      border-radius: 0 20px 20px 0;
    }
  }

  svg {
    margin-left: 16px;
    color: ${({ theme }) => theme.COLORS.GRAY_100};
    width: 22px;
    height: 22px;
  }

  @media (max-width: 1023px) and (min-width: 768px) {
  }

  @media (max-width: 767px) {
    width: 90%;
    height: 40px;
    top: 90px;
    left: 5%;

    &:focus-within {
      width: calc(100% - 20px);
    }
  }
`;
