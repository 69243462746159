import styled from "styled-components";

export const Container = styled.div`
  width: 100vw;
  position: sticky;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: baseline;

  .header-bar {
    width: 60%;
    height: auto;
    object-fit: cover;
    object-position: 100% 0;
  }

  .logo {
    width: 170px;
    height: auto;
    margin-bottom: 20px;
  }
`;
