import styled from "styled-components";
import homeUtils from "../../utils/home-utils";

export const Container = styled.div`
  width: 100%;
  min-height: calc(100vh - 286px);
  background-color: ${({ theme }) => theme.COLORS.BACKGROUND_800};

  .mobile {
    display: none;
  }

  .infinite-scroll {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  .print {
    display: none;
  }

  @media (max-width: 767px) {
    min-height: calc(100vh - 246px);
    margin-top: 40px;

    .desktop {
      display: none;
    }

    .mobile {
      display: flex;
    }
  }

  @media print {
    body {
      margin: 0;
    }

    .no-print {
      display: none;
    }

    .print {
      display: initial;
    }
  }
`;

export const DashboardWrapper = styled.div`
  width: 100%;
  height: calc(100vh - 105px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    width: 300px;
  }

  h1 {
    color: ${({ theme }) => theme.COLORS.WHITE};
    font-size: 40px;
    text-align: center;
  }
`;

export const Brand = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 250px;
  height: 105px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 14px 0;
  border-bottom: 1px solid ${({ theme }) => theme.COLORS.GRAY};
  background-color: ${({ theme, selected }) =>
    selected ? theme.COLORS.ORANGE : theme.COLORS.BACKGROUND_900};
  z-index: 5;

  h2 {
    font-size: 15px;
    text-align: center;
    margin: 0;
    color: ${({ theme, selected }) =>
      selected ? theme.COLORS.BACKGROUND_900 : theme.COLORS.WHITE};
    cursor: pointer;
  }

  img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    cursor: pointer;
    object-fit: cover;
    border: ${({ theme, selected }) =>
      selected ? `3px solid ${theme.COLORS.BACKGROUND_900}` : "none"};
  }

  @media (max-width: 1023px) {
    top: 105px;
    left: ${({ displayMenu }) => (displayMenu ? "0" : "-250px")};
    z-index: 3;
    border-right: 1px solid ${({ theme }) => theme.COLORS.GRAY};
  }

  @media (max-width: 767px) {
    top: 80px;
  }
`;

export const Content = styled.div`
  margin-top: 105px;
  margin-left: 250px;
  width: auto;
  min-height: calc(100vh - 105px);
  padding: 0 5%;
  overflow-y: auto;

  @media (max-width: 1023px) {
    margin-left: 0;
    padding: 0 10x;

    section {
      margin-top: ${({ path }) =>
        homeUtils.displaySearchBar(path) ? "28px" : "0"};
    }
  }

  @media (max-width: 767px) {
    min-height: calc(100vh - 80px);
    margin-top: 80px;
  }

  @media print {
    margin-top: 30px;
  }
`;

export const Newnote = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 250px;
  height: 80px;
  background-color: ${({ theme }) => theme.COLORS.ORANGE};
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;

  a {
    color: ${({ theme }) => theme.COLORS.BACKGROUND_900};
  }

  svg {
    margin-right: 8px;
  }

  @media (max-width: 1023px) {
    left: ${({ displayMenu }) =>
      displayMenu ? "calc(100vw - 250px)" : "calc(100vw)"};
    z-index: 3;
  }
`;

export const Directory = styled.button`
  width: 160px;
  height: 160px;
  max-height: 160px;
  font-size: 18px;
  color: ${({ theme }) => theme.COLORS.GRAY_100};
  background-color: ${({ theme }) => theme.COLORS.BACKGROUND_700};
  border: 1px solid ${({ theme }) => theme.COLORS.GRAY};
  border-radius: 20px;
  cursor: pointer;
  margin: 0 20px 20px 0;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.3);
  white-space: pre-wrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 24px;

  &:hover {
    background-color: ${({ theme }) => theme.COLORS.BACKGROUND_600};

    img {
      width: 68px;
      height: 68px;
    }
  }

  img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
  }

  h5 {
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }

  div {
    width: 100%;
    height: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
  }

  svg {
    display: none;
  }

  @media (max-width: 767px) {
    width: 100%;
    height: 40px;
    font-size: 14px;
    border-radius: 10px;
    margin: 0 0 10px 0;

    div {
      height: 70%;
      flex-direction: row;
      justify-content: left;
    }

    img {
      width: 26px;
      height: 26px;
      margin-right: 10px;
    }

    svg {
      display: initial;
      margin-left: auto;
    }
  }
`;

export const ListItem = styled(Directory)`
  width: 100%;
  height: 50px;
  border-radius: 10px;
  padding: 0 10px;
  margin: 5px 0;

  div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 100%;
  }

  h5 {
    font-size: 18px;
    font-weight: 400;
    color: ${({ theme }) => theme.COLORS.WHITE};
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: calc(100% - 20px);
  }

  svg {
    display: initial;
  }

  img {
    width: 36px;
    height: 36px;
  }

  &:hover {
    svg {
      color: ${({ theme }) => theme.COLORS.WHITE};
    }

    img {
      width: 36px;
      height: 36px;
    }
  }

  @media (max-width: 767px) {
    h5 {
      font-size: 14px;
    }
  }
`;

export const AddListItem = styled.button`
  width: 100%;
  height: 40px;
  font-size: 18px;
  align-items: center;
  padding: 10px;
  display: flex;
  color: ${({ theme }) => theme.COLORS.GRAY_100};
  background-color: ${({ theme }) => theme.COLORS.BACKGROUND_600};
  border: 1px solid ${({ theme }) => theme.COLORS.GRAY};
  border-radius: 20px;
  cursor: pointer;
  margin-bottom: 10px;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.3);

  svg {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    color: ${({ theme }) => theme.COLORS.GRAY_100};
  }

  &:hover {
    background-color: ${({ theme }) => theme.COLORS.WHITE};
    color: ${({ theme }) => theme.COLORS.BACKGROUND_900};

    svg {
      color: ${({ theme }) => theme.COLORS.BACKGROUND_900};
    }
  }
`;

export const ProductSearch = styled.input`
  height: 40px;
  width: 100%;
  text-overflow: ellipsis;

  margin: 12px 0;
  color: ${({ theme, disabled }) =>
    disabled ? theme.COLORS.GRAY_100 : theme.COLORS.WHITE};
  background: transparent;
  border: 0;
  border-bottom: 1px solid ${({ theme }) => theme.COLORS.GRAY_100} !important;
  font-size: 18px;

  &:placeholder {
    color: ${({ theme }) => theme.COLORS.GRAY_300};
  }

  &:focus {
    box-shadow: 0 0 0 0;
    border: 0 none;
    outline: 0;
  }

  &:autofill {
    box-shadow: 0 0 0 30px ${({ theme }) => theme.COLORS.BACKGROUND_900} inset;
    color: ${({ theme }) => theme.COLORS.WHITE}!important;
    border-radius: 0 10px 10px 0;
  }

  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px ${({ theme }) => theme.COLORS.BACKGROUND_900}
      inset;
    -webkit-text-fill-color: ${({ theme }) => theme.COLORS.WHITE}!important;
    border-radius: 0 10px 10px 0;
  }

  @media (max-width: 767px) {
    height: 36px;
    font-size: 14px;
    margin: 8px 0;
  }
`;
