import useAsync from "../../useAsync";

import * as productApi from "../../../services/productApi";
import useToken from "../../useToken";

export default function useProductByAcronym() {
  const token = useToken();

  const {
    data: productByAcronym,
    loading: productByAcronymLoading,
    error: productByAcronymError,
    act: getProductByAcronym,
  } = useAsync((data) => productApi.getProductByAcronym(data, token), false);

  return {
    productByAcronym,
    productByAcronymLoading,
    productByAcronymError,
    getProductByAcronym,
  };
}
