import { useState } from "react";

import theme from "../../../../styles/theme";
import { Container, Row } from "./styles";
import ChecklistSelect from "../../../../components/forms/report-form/ChecklistSelect";
import { Tooltip } from "../../../../pages/Home/Reports/PlanAnalysis/styles";

export default function PlanAnalysisSummary({
  sections,
  enabled,
  setAnalysisData,
  options,
}) {
  return (
    <Container theme={theme}>
      <Row className="title" theme={theme}>
        <span className="index">Item</span>
        <span className="description">Planos e Documentos Técnicos</span>
        <span className="review">REV.</span>
        <span className="status desktop">SITUAÇÃO</span>
        <span className="status mobile">SIT.</span>
      </Row>

      {sections.map((item, index) => {
        return (
          <ChecklistItem
            key={index}
            item={item}
            index={index}
            sectionKey={item.sectionKey}
            enabled={enabled}
            setAnalysisData={setAnalysisData}
            options={options}
          />
        );
      })}
    </Container>
  );
}

function ChecklistItem({
  item,
  index,
  sectionKey,
  enabled,
  setAnalysisData,
  options,
}) {
  const [showTooltip, setShowTooltip] = useState(false);

  function handleSelect({ value }) {
    setAnalysisData((prevData) => ({
      ...prevData,
      summary: prevData.summary.map((summaryItem, i) =>
        i === index ? { ...summaryItem, status: value } : summaryItem
      ),
    }));
  }

  return (
    <>
      <Row
        key={index}
        theme={theme}
        enabled={false}
        onMouseEnter={() =>
          item.user.id ? setShowTooltip(true) : setShowTooltip(false)
        }
        onMouseLeave={() => setShowTooltip(false)}
      >
        <Tooltip
          theme={theme}
          show={showTooltip}
        >{`Alterado por ${item.user.name}`}</Tooltip>
        <span className="index">{sectionKey}</span>
        <span className="description">
          <span>
            <p>{item.name}</p>
          </span>
        </span>
        <span className="review">{item.rev}</span>
        <span className="status">
          <ChecklistSelect
            id={item.id}
            placeholder={enabled ? "Selecione..." : ""}
            enabled={enabled}
            list={options}
            selected={item.status}
            required
            handleForm={handleSelect}
          />
        </span>
      </Row>
    </>
  );
}
