import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { HiOutlineDocumentSearch } from "react-icons/hi";
import {
  AiFillMinusCircle,
  AiOutlineCaretDown,
  AiOutlineCaretUp,
} from "react-icons/ai";
import { IoAddCircleSharp } from "react-icons/io5";
import dayjs from "dayjs";

import { Section } from "../../../../components/Section";
import { AddSubitem, Container, Divider, Edit, Form, Row } from "../styles";
import theme from "../../../../styles/theme";
import ReportInfo from "../../../../components/forms/report-form/ReportInfo";
import useVessel from "../../../../hooks/api/vessel/useVessel";
import toastUtils from "../../../../utils/toast-utils";
import useInspectionData from "../../../../hooks/api/inspection/useInspectionData";
import useInspectionDetailsByProduct from "../../../../hooks/api/inspection/useInspectionDetailsByProduct";
import reportUtils from "../../../../utils/report-utils";
import TextSelect from "../../../../components/forms/report-form/TextSelect";
import useProductByAcronym from "../../../../hooks/api/product/useProductByAcronym";
import useCompany from "../../../../hooks/api/company/useCompany";
import generalUtils from "../../../../utils/general-utils";
import enrollmentUtils from "../../../../utils/enrollment-utils";
import Loader from "../../../../components/Loader";
import { FormsWrapper, InfoTitle } from "./styles";
import usePlanAnalysis from "../../../../hooks/api/report/usePlanAnalysis";
import PlanAnalysisSummary from "../../../../components/forms/report-form/PlanAnalysisSummary";
import PlanAnalysisSection from "../../../../components/forms/report-form/PlanAnalysisSection";
import Button from "../../../../components/forms/report-form/Button";
import UserContext from "../../../../contexts/UserContext";
import Textarea from "../../../../components/forms/report-form/Textarea";
import Label from "../../../../components/forms/report-form/Label";
import useSavePlanAnalysis from "../../../../hooks/api/report/useSavePlanAnalysis";
import usePlanAnalysisInfo from "../../../../hooks/api/report/usePlanAnalysisInfo";
import useChecklist from "../../../../hooks/api/report/useChecklist";
import useVerificationTypes from "../../../../hooks/api/inspection/useVerificationTypes";
import useJasperPlanAnalysis from "../../../../hooks/api/jasper/useJasperPlanAnalysis";
import DownloadButton from "../../../../components/forms/report-form/DonwloadButton";

export default function PlanAnalysis() {
  const { reportId } = useParams();
  const newReport = isNaN(reportId);
  const { userData } = useContext(UserContext);
  const { getProductByAcronym, productByAcronymLoading } =
    useProductByAcronym();
  const { getVessel, vesselLoading } = useVessel();
  const { getInspectionData, inspectionDataLoading } = useInspectionData();
  const { getInspectionDetailsByProduct, inspectionDetailsByProductLoading } =
    useInspectionDetailsByProduct();
  const { getPlanAnalysis, planAnalysisLoading } = usePlanAnalysis();
  const { getPlanAnalysisInfo, planAnalysisInfoLoading } =
    usePlanAnalysisInfo();
  const { getCompany, companyLoading } = useCompany();
  const { savePlanAnalysis, savePlanAnalysisLoading } = useSavePlanAnalysis();
  const { getChecklist, checklistLoading } = useChecklist();
  const { getVerificationTypes, verificationTypesLoading } =
    useVerificationTypes();
  const { saveJasperPlanAnalysis, jasperPlanAnalysisLoading } =
    useJasperPlanAnalysis();
  const navigate = useNavigate();
  const [analysisList, setAnalysisList] = useState([]);
  const [verificationTypes, setVerificationTypes] = useState([]);
  const [vesselInfo, setVesselInfo] = useState([]);
  const [companyInfo, setCompanyInfo] = useState([]);
  const [vesselMainFeaturesInfo, setVesselMainFeaturesInfo] = useState([]);
  const [analysis, setAnalysis] = useState(reportUtils.emptyPlanAnalysisOption);
  const [analysisData, setAnalysisData] = useState(
    reportUtils.emptyPlanAnalysisData
  );
  const [reportData, setReportData] = useState({});
  const [form, setForm] = useState(reportUtils.emptyPlanAnalysisData);
  const [comments, setComments] = useState([]);
  const [title, setTitle] = useState("Relatórios/Análise de Planos");
  const [editing, setEditing] = useState(false);
  const [allowed, setAllowed] = useState(false);
  const [options, setOptions] = useState([]);
  const [summaryOptions, setSummaryOptions] = useState([]);

  useEffect(() => {
    loadAnalysisOptions();
    loadAnalysisInfo();
    loadVerificationTypes();
  }, []);

  useEffect(() => {
    loadVesselData();
    loadPlanAnalysisData();
    loadStautsOptions();
  }, [analysis.analysisId]);

  useEffect(() => {
    setComments(analysisData.obsList);
  }, [analysisData.obsList]);

  async function loadAnalysisOptions() {
    if (!newReport) return;

    try {
      const product = await getProductByAcronym("ADP");

      const response = await getInspectionDetailsByProduct(product.id);

      if (response)
        setAnalysisList(
          response.map((item) => ({
            value: item.id,
            label: `${item.id} - ${item.product.acronym} ${item.vessel.name}(${item.company.name})`,
            analysisId: item.inspectionId,
            formId: item.formId,
          }))
        );

      setTitle(title + "/Novo Relatório");
    } catch (error) {
      toastUtils.toaster({
        message: error.response.data,
        type: toastUtils.type.error,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    }
  }

  async function loadVerificationTypes() {
    try {
      const response = await getVerificationTypes();

      if (response) setVerificationTypes(response);
    } catch (error) {
      toastUtils.toaster({
        message: error.response.data,
        type: toastUtils.type.error,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    }
  }

  async function loadAnalysisInfo() {
    if (newReport) return;

    try {
      const response = await getPlanAnalysisInfo(reportId);

      if (response) {
        const result = {
          value: response.id,
          label: `${response.id} - ${response.product.acronym} ${response.vessel.name}(${response.company.name})`,
          analysisId: response.inspectionId,
          formId: response.formId,
        };

        setAnalysisList([result]);
        setAnalysis(result);
      }
    } catch (error) {
      toastUtils.toaster({
        message: error.response.data,
        type: toastUtils.type.error,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    }
  }

  async function loadVesselData() {
    if (!analysis.analysisId) return;

    try {
      const analysisData = await getInspectionData(analysis.analysisId);

      const vesselData = await getVessel(analysisData.vessel.id);

      const companyData = await getCompany(vesselData.vessel.company.value);

      if (vesselData)
        setVesselInfo([
          { label: "Nome da Embarcação", value: vesselData.vessel.name },
          {
            label: "Tipo de Embarcação",
            value: vesselData.vessel.vesselType.label,
          },
          {
            label: "Tipo de Navegação",
            value: vesselData.vessel.navigationType.label || "N/A",
          },
          {
            label: "Atividade ou Serviço",
            value: vesselData.vessel.vesselActivityServiceType.label,
          },
          {
            label: "OR anterior",
            value: vesselData.vessel.previousOr || "N/I",
          },
          {
            label: "Número de Licença",
            value: vesselData.vessel.registration || "N/I",
          },
          {
            label: "Material do Casco",
            value: vesselData.vessel.hullMaterial || "N/I",
          },
          {
            label: "Propulsão",
            value: vesselData.vessel.propulsion
              ? "Com propulsão"
              : "Sem propulsão",
          },
          { label: "Ano de Construção", value: vesselData.vessel.year.label },
          {
            label: "Classe",
            value: vesselData.vessel.vesselClass.label || "N/I",
          },
          { label: "AB", value: vesselData.vessel.grossTonnage || "N/I" },
        ]);

      setCompanyInfo([
        { label: "Armador", value: companyData.company.tradeName },
        {
          label: "Nacionalidade",
          value:
            generalUtils.nationalitiesConvertion[vesselData.vessel.flag.label],
        },
        {
          label: "CPF/CNPJ",
          value:
            companyData.company.cnpj.length > 11
              ? enrollmentUtils.cnpjMask(companyData.company.cnpj)
              : enrollmentUtils.cpfMask(companyData.company.cnpj),
        },
      ]);

      setVesselMainFeaturesInfo([
        {
          label: "Comprimento Total",
          value: vesselData.vessel.totalLength || "N/I",
        },
        { label: "Boca", value: vesselData.vessel.bow || "N/I" },
        { label: "Pontal", value: vesselData.vessel.draught || "N/I" },
        { label: "Calado", value: vesselData.vessel.loadedDraft || "N/I" },
      ]);

      setReportData({
        ...reportData,
        vessel: vesselData.vessel.name,
        vesselType: vesselData.vessel.vesselType.label,
        navigationType: vesselData.vessel.navigationType.label || "N/A",
        activityServiceType:
          vesselData.vessel.vesselActivityServiceType.label || "N/A",
        previousOr: vesselData.vessel.previousOr || "N/I",
        registration: vesselData.vessel.registration || "N/I",
        hullMaterial: vesselData.vessel.hullMaterial || "N/I",
        propulsion: vesselData.vessel.propulsion
          ? "Com propulsão"
          : "Sem propulsão",
        constructionYear: vesselData.vessel.year.label.toString(),
        vesselClass: vesselData.vessel.vesselClass.label || "N/I",
        grossTonnage: vesselData.vessel.grossTonnage,
        company: companyData.company.tradeName,
        flag: generalUtils.nationalitiesConvertion[
          vesselData.vessel.flag.label
        ],
        document:
          companyData.company.cnpj.length > 11
            ? enrollmentUtils.cnpjMask(companyData.company.cnpj)
            : enrollmentUtils.cpfMask(companyData.company.cnpj),
        totalLength: vesselData.vessel.totalLength || "N/I",
        bow: vesselData.vessel.bow || "N/I",
        draught: vesselData.vessel.draught || "N/I",
        draft: vesselData.vessel.loadedDraft || "N/I",
      });
    } catch (error) {
      toastUtils.toaster({
        message: error.response.data,
        type: toastUtils.type.error,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    }
  }

  async function loadPlanAnalysisData() {
    if (!analysis.analysisId) return;

    try {
      const response = await getPlanAnalysis({
        reportId: newReport ? 0 : reportId,
        formId: analysis.formId,
        inspectionId: analysis.analysisId,
      });

      const result = newReport
        ? { ...response, verificationType: form.verificationType }
        : response;

      if (response) {
        setAnalysisData(result);
        setForm(result);
        if (
          userData.enrollmentId === response.coordinatorId ||
          userData.enrollmentId === response.inspectorId
        )
          setAllowed(true);

        if (!newReport) {
          const analysisName = `[${response.traceabilityCode}] ${response.vessel.name}(${response.company.name})`;

          setTitle(
            `${title
              .replace("/Novo Relatório", "")
              .replace(`/${analysisName}`, "")}/${analysisName}`
          );
        }
      }

      if (newReport) setEditing(true);
    } catch (error) {
      toastUtils.toaster({
        message: error.response.data,
        type: toastUtils.type.error,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    }
  }

  async function loadStautsOptions() {
    if (!analysis.analysisId) return;

    try {
      const response = await getChecklist(analysis.formId);

      if (response) {
        setOptions(response.itemStatusList);

        setSummaryOptions(response.sectionStatusList);
      }
    } catch (error) {
      toastUtils.toaster({
        message: error.response.data,
        type: toastUtils.type.error,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    }
  }

  async function loadPDF() {
    let sections = [];

    const summary = form.summary.map((item) => ({
      CDLISTAVRF: item.sectionKey,
      NMLISTAVRF: item.name,
      REVISAO: item.rev.toString(),
      NMSTTLISTAVRF: item.status.label,
    }));

    form.sections.forEach((section) => {
      section.items.forEach((item, index) => {
        sections.push({
          CDLISTAVRF: `Seção ${section.sectionKey}: ${section.name}`,
          CDITEM: `${section.sectionKey}${index + 1}`,
          REFERENCIA: item.reference || "-",
          OBS: item.comment,
          PRAZOEXIG: dayjs(item.deadline).format("DD/MM/YYYY"),
          NMSITITEM: item.status.label,
        });
      });
    });

    const commentList = comments.map((item, index) => ({
      INDEX: index + 1,
      OBS: item.text,
    }));

    const body = {
      parameters: {
        LISTA_VERIFICACAO: sections,
        LISTA_REFERENCIAS: summary,
        LISTA_COMENTARIOS: commentList,
      },
      fields: {
        ID: Number(reportId),
        COD: form.traceabilityCode,
        REVISAO: form.review,
        CRIADO_EM: dayjs(form.issueDate).format("DD [de] MMMM [de] YYYY[.]"),
        NOME_COMPLETO: form.user.name,
        CREA: `CREA-${form.user.crea.uf}: ${form.user.crea.number}`,
        NMEMBARCACAO: reportData.vessel.toUpperCase(),
        NMTPEMB: reportData.vesselType,
        NMTPNAV: reportData.navigationType,
        NMTPATVSVCEMB: reportData.activityServiceType,
        NMTPVERIFICACAO: form.verificationType.label,
        ORANTERIOR: reportData.previousOr,
        NUMINSCR: reportData.registration,
        MATCASC: reportData.hullMaterial,
        PROP: reportData.propulsion,
        ANO: reportData.constructionYear,
        NMCLASSEEMB: reportData.vesselClass,
        AB: reportData.grossTonnage,
        NMFNT: reportData.company,
        BANDEIRA: reportData.flag,
        CNPJ: reportData.document,
        COMPTOT: reportData.totalLength,
        BOCA: reportData.bow,
        PONTAL: reportData.draught,
        CALCAR: reportData.draft,
      },
    };

    try {
      const response = await saveJasperPlanAnalysis(body);

      navigate(`/painel/relatorios/pdf-preview/${response.fileName}`, {
        state: { fileSource: response.fileSource },
      });
    } catch (error) {
      toastUtils.toaster({
        message: error?.response?.data || "Erro ao gerar o arquivo PDF",
        type: toastUtils.type.error,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    }
  }

  async function handleSubmit(e) {
    e.preventDefault();

    const items = form.summary.map((summaryItem) => ({
      listStatusId: summaryItem.status.value,
      listAnswerId: summaryItem.listAnswerId,
      userId: summaryItem.user.id,
      review: isNaN(summaryItem.rev) ? 0 : summaryItem.rev,
      items:
        form.sections
          .find((section) => section.sectionKey === summaryItem.sectionKey)
          ?.items.map((item) => ({
            listItemStatusId: item.status.value,
            listItemAnswerId: item.listItemAnswerId,
            userId: item.user.id,
            deadline: new Date(item.deadline),
          })) || [],
    }));

    const body = {
      inspectionId: form.inspectionId,
      inspectionDetailId: form.inspectionDetailId,
      verificationTypeId: form.verificationType.value,
      review: newReport ? 0 : form.review + 1,
      sections: items,
      obsList: comments,
    };

    try {
      const response = await savePlanAnalysis(body);

      setEditing(false);

      const analysisName = `[${response.traceabilityCode}] ${response.vessel.name}(${response.company.name})`;

      setTitle(
        `${title
          .replace("/Novo Relatório", "")
          .replace(`/${analysisName}`, "")}/${analysisName}`
      );

      navigate(`/painel/relatorios/analisedeplanos/${response.reportId}`);

      loadPlanAnalysisData();

      toastUtils.toaster({
        message: "Informações salvas com sucesso!",
        type: toastUtils.type.success,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    } catch (error) {
      toastUtils.toaster({
        message: error.response.data,
        type: toastUtils.type.error,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    }
  }

  function handleReview(sectionIndex) {
    if (newReport || !editing) return;

    const rev =
      form.summary[sectionIndex].rev === "-"
        ? 0
        : Number(form.summary[sectionIndex].rev);

    if (
      analysisData.summary[sectionIndex].rev === form.summary[sectionIndex].rev
    )
      setForm((prevData) => ({
        ...prevData,
        summary: prevData.summary.map((summaryItem, i) =>
          i === sectionIndex
            ? {
                ...summaryItem,
                rev: rev + 1,
                user: {
                  name: userData.user.name,
                  id: userData.enrollmentId,
                },
              }
            : summaryItem
        ),
      }));
  }

  return (
    <Section title={title}>
      <Container theme={theme}>
        <FormsWrapper theme={theme}>
          <Form onSubmit={handleSubmit}>
            {productByAcronymLoading ||
            inspectionDetailsByProductLoading ||
            verificationTypesLoading ? (
              <Loader theme={theme} />
            ) : (
              <div>
                <h3>Dados da Análise</h3>
                <Row>
                  <span className="analysis">
                    <Label id="analysis" text="Análise" />
                    <TextSelect
                      id="analysis"
                      placeholder="Selecione..."
                      icon={HiOutlineDocumentSearch}
                      enabled={newReport}
                      list={analysisList}
                      selected={analysis}
                      required
                      form={analysis}
                      setForm={setAnalysis}
                      handleForm={({ value }) => setAnalysis(value)}
                    />
                  </span>

                  <span className="verificationType">
                    <Label id="verificationType" text="Tipo de Verificação" />
                    <TextSelect
                      id="verificationType"
                      placeholder="Selecione..."
                      icon={HiOutlineDocumentSearch}
                      enabled={newReport}
                      list={verificationTypes}
                      selected={form.verificationType}
                      required
                      form={form}
                      setForm={setForm}
                      handleForm={({ value }) =>
                        setForm({ ...form, verificationType: value })
                      }
                    />
                  </span>
                </Row>
              </div>
            )}

            {analysis.analysisId !== 0 && form.verificationType.value !== 0 && (
              <>
                {vesselLoading ||
                companyLoading ||
                inspectionDataLoading ||
                planAnalysisInfoLoading ||
                planAnalysisLoading ||
                checklistLoading ? (
                  <Loader theme={theme} />
                ) : (
                  <>
                    <Divider theme={theme} />

                    <InfoSection
                      info={vesselInfo}
                      title="Dados da Embarcação"
                    />

                    <Divider theme={theme} />

                    <InfoSection info={companyInfo} title="Armador" />

                    <Divider theme={theme} />

                    <InfoSection
                      info={vesselMainFeaturesInfo}
                      title="Características Principais da Embarcação"
                    />
                  </>
                )}

                <Divider theme={theme}></Divider>

                {!newReport && !editing && (
                  <DownloadButton
                    loading={jasperPlanAnalysisLoading}
                    action={loadPDF}
                    margin={form.allowReview ? "50px" : "-70px"}
                  />
                )}

                {!newReport && allowed && form.allowReview && (
                  <Edit
                    theme={theme}
                    onClick={(e) => {
                      e.preventDefault();

                      if (editing) setForm(analysisData);
                      else if (!allowed) {
                        toastUtils.toaster({
                          message:
                            "Você não tem permissão para editar os dados deste relatório!",
                          type: toastUtils.type.error,
                          position: toastUtils.position.topCenter,
                          theme: toastUtils.theme.colored,
                        });

                        return;
                      }

                      setEditing(!editing);
                    }}
                  >
                    {editing ? "Cancelar" : "Emitir Revisão"}
                  </Edit>
                )}

                <div>
                  <h3>Referências Utilizadas na Revisão</h3>

                  <PlanAnalysisSummary
                    sections={form.summary}
                    enabled={editing && allowed}
                    setAnalysisData={setForm}
                    options={summaryOptions}
                  />
                </div>

                <Divider theme={theme}></Divider>

                <div>
                  <h3>Detalhes e Comentários do Documento</h3>

                  {form.sections.map((section, index) => (
                    <PlanAnalysisSection
                      key={index}
                      sectionIndex={index}
                      section={section}
                      enabled={editing && allowed}
                      setAnalysisData={setForm}
                      options={options}
                      handleReview={handleReview}
                    />
                  ))}
                </div>

                <Divider theme={theme}></Divider>

                <CommentSection
                  comments={comments}
                  setComments={setComments}
                  analysisData={analysisData}
                  enabled={editing && allowed}
                />

                {!newReport && (
                  <>
                    <Divider theme={theme}></Divider>
                    <h3>Emitido por:</h3>
                    <p>{editing ? userData.fullName : form.user?.name}</p>
                  </>
                )}

                <Button
                  className="submit"
                  title="Salvar"
                  enabled={editing && allowed && !savePlanAnalysisLoading}
                />
                {newReport && (
                  <Button
                    className="cancel"
                    title="Cancelar"
                    enabled={editing && allowed && !savePlanAnalysisLoading}
                    type="button"
                    onClick={() => navigate(-1)}
                  />
                )}
              </>
            )}
          </Form>
        </FormsWrapper>
      </Container>
    </Section>
  );
}

function InfoSection({ info, title }) {
  const [display, setDisplay] = useState(false);
  return (
    <div>
      <InfoTitle theme={theme}>
        <h3>{title}</h3>
        {display ? (
          <AiOutlineCaretUp onClick={() => setDisplay(false)} />
        ) : (
          <AiOutlineCaretDown onClick={() => setDisplay(true)} />
        )}
      </InfoTitle>

      {display && <ReportInfo info={info} />}
    </div>
  );
}

function CommentSection({ comments, setComments, analysisData, enabled }) {
  useEffect(() => {
    setComments(analysisData.obsList);
  }, [enabled]);

  function handleAddComment() {
    setComments([
      ...comments,
      {
        id: 0,
        text: "",
      },
    ]);
  }

  return (
    <div>
      <h3>Observações</h3>
      {comments.map((comment, index) => (
        <CommentItem
          key={index}
          comment={comment}
          index={index}
          enabled={enabled}
          comments={comments}
          setComments={setComments}
        />
      ))}

      {enabled && (
        <AddSubitem
          theme={theme}
          disabled={!enabled}
          onClick={handleAddComment}
        >
          <IoAddCircleSharp />
          <p>Adicionar observação</p>
        </AddSubitem>
      )}
    </div>
  );
}

function CommentItem({ comment, index, enabled, comments, setComments }) {
  function handleChange({ index, value, name }) {
    let result = [...comments];

    result[index] = { ...result[index], [name]: value };

    setComments(result);
  }

  function handleDelete() {
    setComments(comments.filter((_, i) => i !== index));
  }

  return (
    <div>
      <Row>
        <span className="textarea">
          {enabled && <AiFillMinusCircle onClick={handleDelete} />}
          <Label id="text" text={`Comentário ${index + 1}`}></Label>
          <Textarea
            id="text"
            placeholder="Observação..."
            disabled={!enabled}
            value={comment.text}
            required
            onChange={(e) =>
              handleChange({
                index,
                name: e.target.id,
                value: e.target.value,
              })
            }
          />
        </span>
      </Row>
    </div>
  );
}
