import serviceUtils from "./service-utils";

const inspectionEmptyDisplayParams = {
  order: { label: "", value: 0 },
  company: { label: "", value: 0 },
  vessel: { label: "", value: 0 },
  coordinator: { label: "", value: 0 },
};

const inspectionOrderList = [
  { label: "Código", value: "ID" },
  { label: "Coordenador", value: "ID_COORDENADOR" },
  { label: "Data de criação", value: "CRIADO_EM" },
  { label: "Última edição", value: "ATUALIZADO_EM" },
];

const inspectionEmptyFilters = {
  companies: [{ value: 0, label: "TODOS" }],
  vessels: [{ value: 0, label: "TODOS" }],
  coordinators: [{ value: 0, label: "TODOS" }],
};

const emptyInspectionForm = {
  id: 0,
  obs: "",
  request: { label: "", value: 0 },
  requestStatus: serviceUtils.status.FINALIZADO,
  coordinator: { label: "", value: 0 },
  inspector: { label: "", value: 0 },
  reports: [],
  cep: "",
  uf: { label: "", value: 0 },
  city: "",
  neighborhood: "",
  street: "",
  number: "",
  complement: "",
  date: null,
  startDate: null,
  endDate: null,
};

const emptyAnalysisForm = {
  id: 0,
  obs: "",
  request: { label: "", value: 0 },
  requestStatus: serviceUtils.status.FINALIZADO,
  coordinator: { label: "", value: 0 },
  inspector: { label: "", value: 0 },
  reports: [],
};

const emptyInspectionData = {
  id: 0,
  obs: "",
  request: { label: "", value: 0 },
  requestStatus: 0,
  status: { id: 0, name: "" },
  company: { id: 0, name: "" },
  vessel: { id: 0, name: "", navigationTypeId: 0 },
  coordinator: { id: 0, name: "" },
  requestedInspections: [],
  inProgressInspections: [],
  pendingInspections: [],
};

const emptyList = [{ label: "Selecione...", value: 0 }];
const emptyInspector = [
  { label: "Selecione...", value: 0, qualifications: [] },
];

const emptyQualificationsForm = {
  creaId: 0,
  products: [],
};

const status = {
  LIBERADO_AGUARDANDO_EXECUCAO: 1,
  AGUARDANDO_ANALISE_VISTORIADOR: 2,
  AGUARDANDO_ANALISE_COORDENADOR: 3,
  AGUARDANDO_ENVIO_EXIGENCIAS: 4,
  AGUARDANDO_RETORNO_CLIENTE: 5,
  AUTORIZADO_PELO_CLIENTE: 6,
  REAGENDAR_NOVA_VISTORIA: 7,
  VISTORIA_FINALIZADA: 8,
};

const inspectionTypes = {
  undefined: "INDEFINIDA",
  analysis: "INTERNA",
  inspection: "EXTERNA",
};

function formatBusyDatesArray(array) {
  const oneArray = array.reduce((accumulator, currentValue) => {
    return accumulator.concat(currentValue);
  }, []);

  const uniqueValuesArray = oneArray.filter((item, index) => {
    return oneArray.indexOf(item) === index;
  });

  return uniqueValuesArray;
}

const inspectionUtils = {
  inspectionEmptyDisplayParams,
  inspectionOrderList,
  inspectionEmptyFilters,
  emptyInspectionForm,
  emptyAnalysisForm,
  emptyInspectionData,
  emptyList,
  emptyQualificationsForm,
  emptyInspector,
  status,
  inspectionTypes,
  formatBusyDatesArray,
};

export default inspectionUtils;
