import api from "./api";

export async function getChecklists({ order, skip, take, filter }, token) {
  const response = await api.get(
    `/report/form/many?order=${order}&skip=${skip}&take=${take}&filter=${filter}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
}

export async function getChecklist(formId, token) {
  const response = await api.get(`/report/form/${formId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

export async function getChecklistFilters(token) {
  const response = await api.get("/report/form/filters", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

export async function getInspectionChecklists(
  { inspectionId, nav, prod },
  token
) {
  const response = await api.get(
    `/report/form/many/params/${inspectionId}?nav=${nav}&prod=${prod}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
}

export async function getChecklistAnswers({ inspectionId, formId }, token) {
  const response = await api.get(
    `/report/form/answers/${formId}?inspectionId=${inspectionId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
}

export async function getDailyReports({ order, skip, take, filter }, token) {
  const response = await api.get(
    `/report/daily/many?skip=${skip}&take=${take}&order=${order}&filter=${filter}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
}

export async function getDailyReportFilters(token) {
  const response = await api.get("/report/daily/filters", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

export async function getDailyReport(reportId, token) {
  const response = await api.get(`/report/daily/${reportId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

export async function postDailyReport(body, token) {
  const response = await api.post("/report/daily/", body, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

export async function deleteDailyReportDetail(detailIds, token) {
  const response = await api.delete(`/report/daily/det/${detailIds}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

export async function getPlanAnalysis(
  { inspectionId, formId, reportId },
  token
) {
  const response = await api.get(
    `/report/plan-analysis/${reportId}?formId=${formId}&inspectionId=${inspectionId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
}

export async function getPlanAnalysisInfo(reportId, token) {
  const response = await api.get(`/report/plan-analysis/info/${reportId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

export async function getPlanAnalysisFilters(token) {
  const response = await api.get("/report/plan-analysis/filters", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

export async function getPlanAnalysisReports(
  { skip, take, filter, order },
  token
) {
  const response = await api.get(
    `/report/plan-analysis/many?skip=${skip}&take=${take}&order=${order}&filter=${filter}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
}

export async function deletePlanAnalysisComment(commentId, token) {
  const response = await api.delete(
    `/report/plan-analysis/comment/${commentId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
}

export async function postPlanAnalysis(body, token) {
  const response = await api.post("/report/plan-analysis/", body, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}
