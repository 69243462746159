import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: auto;
  padding-right: 10%;

  h5 {
    margin: 0;
    margin-bottom: 10px;
    color: ${({ theme }) => theme.COLORS.GRAY_100};
    display: none;
  }

  h3 {
    margin-bottom: 40px;
  }

  h2 {
    font-size: 18px;
    font-weight: 700;
    cursor: default;
  }

  h1 {
    font-size: 18px;
    margin: 0 0 20px 0;
  }

  & > div {
    height: auto;
    line-height: 22px;
  }

  section {
    height: auto;

    div {
      width: 100%;
      max-height: 1000px;
    }
  }

  div {
    cursor: default;
  }

  .card-content-wrapper {
    height: auto;
  }

  .timeline-item-title {
    font-size: 14px;
  }

  @media (max-width: 1023px) {
    padding-right: 0;

    h1 {
      margin-bottom: 0;
    }

    h5 {
      display: initial;
    }
  }

  @media (max-width: 767px) {
    margin-bottom: 100px;

    & > div > div > div > div > div:first-child > div {
      line-height: 14px;
      padding: 1px;
    }

    div {
      padding: 0;
    }

    div:first-child {
      font-size: 10px;
    }

    h5 {
      font-size: 12px;
      line-height: 20px;
    }

    h3 {
      margin-bottom: 20px;
    }

    h2 {
      font-size: 16px;
      margin-bottom: 10px;
    }

    h1 {
      font-size: 16px;
    }

    section {
      div {
        max-height: 1000px;
      }
    }
  }
`;

export const ItemWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 10px !important;
  pointer-events: none;

  img {
    width: 70px;
    height: 70px;
    object-fit: cover;
    border-radius: 50%;
    margin: 0 20px;
    border: 1px solid ${({ theme }) => theme.COLORS.GRAY_100};
  }

  p {
    color: ${({ theme }) => theme.COLORS.GRAY_100};
    margin: 0;
    font-size: 14px;
    word-break: break-word;
  }

  h1 {
    color: ${({ theme, selected }) =>
      selected ? theme.COLORS.ORANGE : theme.COLORS.WHITE};
    word-break: break-word;
  }

  h6 {
    color: ${({ theme }) => theme.COLORS.GRAY_100};
    font-size: 14px;
    margin: 5px 0;
    word-break: break-word;
  }

  .user {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: baseline;
    margin: 20px 0;
  }

  .os {
    display: flex;
    flex-direction: column;
    padding: 0 20px;

    span {
      display: flex;
      flex-direction: row;
      width: auto;
      margin-right: auto;
      cursor: pointer;
      padding: 5px 0;
      z-index: 2;
      pointer-events: auto;
    }

    span:hover {
      svg,
      p {
        color: ${({ theme }) => theme.COLORS.WHITE};
      }
    }

    svg {
      width: 20px;
      height: 20px;
      color: ${({ theme }) => theme.COLORS.GRAY_100};
      margin-right: 10px;
    }
  }

  @media (max-width: 767px) {
    img {
      width: 50px;
      height: 50px;
      margin: 0 16px 10px 0;
    }

    h1 {
      line-height: 18px;
      width: inherit;
    }

    .user {
      width: 100%;
      flex-wrap: wrap;
      flex-direction: column;
      align-items: baseline;
    }
  }
`;
