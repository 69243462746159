import { CgReorder } from "react-icons/cg";
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { BsPlusCircleFill } from "react-icons/bs";

import { Section } from "../../../components/Section";
import Select from "../../../components/forms/enrollment-form/Select";
import { AddListItem, Container, ListItem } from "../styles";
import { ParamsWrapper } from "./styles";
import enrollmentUtils from "../../../utils/enrollment-utils";
import theme from "../../../styles/theme";
import useVessels from "../../../hooks/api/vessel/useVessels";
import ListWrapper from "../../../components/ListWrapper";
import toastUtils from "../../../utils/toast-utils";
import { IoChevronForward } from "react-icons/io5";
import useVesselFilters from "../../../hooks/api/vessel/useVesselFilters";
import { BiFilter } from "react-icons/bi";
import FilterSelector from "../../../components/FilterSelector";
import UserContext from "../../../contexts/UserContext";

export default function Vessels() {
  const navigate = useNavigate();
  const responseLength = 20;
  const { userData } = useContext(UserContext);
  const allowed = userData.permission.label !== "DPC";
  const { getVessels, vesselsLoading } = useVessels();
  const { getVesselFilters } = useVesselFilters();
  const [vessels, setVessels] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const [filters, setFilters] = useState(enrollmentUtils.vesselEmptyFilters);
  const [filter, setFilter] = useState({});
  const [displayParams, setDisplayParams] = useState(
    enrollmentUtils.vesselEmptyDisplayParams
  );
  const search = useLocation().search.replace("?search=", "");
  const [displayFilters, setDisplayFilters] = useState(false);

  useEffect(() => {
    loadVessels();
    loadFilters();
  }, []);

  useEffect(() => {
    orderVessels();
  }, [displayParams.order, filter]);

  useEffect(() => {
    handleVesselSearch();
  }, [search]);

  useEffect(() => {
    handleFilter();
    handleVesselSearch();
  }, [
    displayParams.company,
    displayParams.vesselType,
    displayParams.navigationType,
    displayParams.vesselActivityServiceType,
  ]);

  function handleVesselSearch() {
    if (!search) return handleFilter();
    const newList = vessels.filter(
      (vessel) =>
        vessel.name.toLowerCase().includes(search.toLowerCase()) ||
        vessel.registration.includes(search.toLowerCase())
    );
    setFilteredList(newList);
  }

  function handleForm({ name, value, form, setForm }) {
    setForm({ ...form, [name]: value });
  }

  function handleFilter() {
    const params = Object.keys(displayParams)
      .filter(
        (key) =>
          Object.prototype.hasOwnProperty.call(displayParams[key], "column") &&
          displayParams[key].value !== 0
      )
      .reduce((acc, key) => {
        acc[displayParams[key].column] = displayParams[key].value;
        return acc;
      }, {});

    setFilter(params);
  }

  async function loadVessels() {
    const skip = vessels.length;
    const take = responseLength;

    try {
      const response = await getVessels({
        order: displayParams.order.value ? displayParams.order.value : "",
        skip,
        take,
        filter: JSON.stringify(filter),
      });

      if (response) {
        setVessels(vessels.concat(response));
        setFilteredList(vessels.concat(response));
      }
    } catch (error) {
      toastUtils.toaster({
        message: error.response.data,
        type: toastUtils.type.error,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    }
  }

  async function loadFilters() {
    try {
      const response = await getVesselFilters();
      setFilters({
        ...filters,
        companies: filters.companies.concat(response.companies),
        vesselTypes: filters.vesselTypes.concat(response.vesselTypes),
        navigationTypes: filters.navigationTypes.concat(
          response.navigationTypes
        ),
        vesselActivityServiceTypes: filters.vesselActivityServiceTypes.concat(
          response.vesselActivityServiceTypes
        ),
      });
    } catch (error) {
      toastUtils.toaster({
        message: "Não foi possível carregar os filtros!",
        type: toastUtils.type.error,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    }
  }

  async function orderVessels() {
    const skip = 0;
    const take = filter ? 10000 : responseLength;

    try {
      const response = await getVessels({
        order: displayParams.order.value ? displayParams.order.value : "",
        skip,
        take,
        filter: JSON.stringify(filter),
      });

      if (response) {
        setVessels(response);
        setFilteredList(response);
      }
    } catch (error) {
      toastUtils.toaster({
        message: error.response.data,
        type: toastUtils.type.error,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    }
  }

  return (
    <Section title={allowed ? "Cadastro/Embarcações" : "Embarcações"}>
      <Container theme={theme}>
        <ParamsWrapper displayFilters={displayFilters}>
          <Select
            id="order"
            icon={CgReorder}
            placeholder={"Ordenar por..."}
            enabled={true}
            list={enrollmentUtils.vesselOrderList}
            selected={displayParams.order}
            form={displayParams}
            setForm={setDisplayParams}
            handleForm={handleForm}
          />

          <FilterSelector
            displayFilters={displayFilters}
            setDisplayFilters={setDisplayFilters}
          />

          <Select
            id="company"
            icon={BiFilter}
            placeholder={"Armador"}
            enabled={true}
            list={filters.companies}
            selected={displayParams.company}
            form={displayParams}
            setForm={setDisplayParams}
            handleForm={handleForm}
            className="filter"
          />

          <Select
            id="vesselType"
            icon={BiFilter}
            placeholder={"Tipo"}
            enabled={true}
            list={filters.vesselTypes}
            selected={displayParams.vesselType}
            form={displayParams}
            setForm={setDisplayParams}
            handleForm={handleForm}
            className="filter"
          />

          <Select
            id="navigationType"
            icon={BiFilter}
            placeholder={"Navegação"}
            enabled={true}
            list={filters.navigationTypes}
            selected={displayParams.navigationType}
            form={displayParams}
            setForm={setDisplayParams}
            handleForm={handleForm}
            className="filter"
          />

          <Select
            id="vesselActivityServiceType"
            icon={BiFilter}
            placeholder={"Atividade/Serviço"}
            enabled={true}
            list={filters.vesselActivityServiceTypes}
            selected={displayParams.vesselActivityServiceType}
            form={displayParams}
            setForm={setDisplayParams}
            handleForm={handleForm}
            className="filter"
          />
        </ParamsWrapper>

        {allowed && (
          <AddListItem theme={theme} onClick={() => navigate(`novo`)}>
            <BsPlusCircleFill />
            <h5>Adicionar Embarcação</h5>
          </AddListItem>
        )}

        <ListWrapper
          offset={responseLength}
          list={vessels}
          loadList={loadVessels}
          loading={vesselsLoading}
        >
          {filteredList.map((vessel, index) => (
            <VesselWrapper key={index} id={vessel.id} name={vessel.name} />
          ))}
        </ListWrapper>
      </Container>
    </Section>
  );
}

function VesselWrapper({ id, name }) {
  const navigate = useNavigate();

  return (
    <ListItem theme={theme} onClick={() => navigate(`${id}`)}>
      <div>
        <h5>{name}</h5>
        <IoChevronForward />
      </div>
    </ListItem>
  );
}
