const serviceEmptyDisplayParams = {
  order: { label: "", value: 0 },
  company: { label: "", value: 0 },
  vessel: { label: "", value: 0 },
  status: { label: "", value: 0 },
};

const serviceOrderList = [
  { label: "Código", value: "ID" },
  { label: "Tipo", value: "CD_TPSOLIC" },
  { label: "Data de criação", value: "CRIADO_EM" },
  { label: "Última edição", value: "ATUALIZADO_EM" },
];

const serviceEmptyFilters = {
  companies: [{ value: 0, label: "TODOS" }],
  vessels: [{ value: 0, label: "TODOS" }],
  status: [{ value: 0, label: "TODOS" }],
};

const emptyService = {
  id: 0,
  createdAt: "",
  type: { typeId: 0, typeName: "" },
  status: { statusId: 0, statusName: "" },
  user: { userId: 0, userName: "" },
  company: { label: "", value: 0 },
  vessel: { label: "", value: 0 },
  obs: "",
  products: [],
};

const emptyServiceForm = {
  company: { label: "", value: 0 },
  vessel: { label: "", value: 0, companyId: 0, navigationType: 0 },
  obs: "",
  os: "",
  osFile: "",
  osFileName: "",
  coordinator: { label: "", value: 0 },
  products: [],
  reports: [],
};

const emptyServiceOption = {
  activityId: 0,
  activityName: "",
  services: [],
};

const emptyServiceOptions = {
  certificationOptions: emptyServiceOption,
  projectOptions: emptyServiceOption,
};

const status = {
  SOLICITADO: 1,
  AGUARDANDO_ANALISE_INTERNA: 2,
  ANALISE_INTERNA_EXECUTADA: 3,
  AGUARDANDO_RETORNO_DO_CLIENTE: 4,
  AUTORIZADO_PELO_CLIENTE: 5,
  FINALIZADO: 6,
};

const types = {
  SOLICITACAO_INICIAL: 1,
  ANALISE_INTERNA: 2,
  SERVIÇO_AUTORIZADO: 3,
  INTERROMPIDO: 4,
};

const confirmationMessages = {
  [status.ANALISE_INTERNA_EXECUTADA]: "Análise enviada para o cliente?",
  [status.AGUARDANDO_RETORNO_DO_CLIENTE]: "Serviço autorizado pelo cliente?",
  [status.AUTORIZADO_PELO_CLIENTE]: "Ordem de serviço emitida?",
};

const serviceUtils = {
  serviceEmptyDisplayParams,
  serviceEmptyFilters,
  serviceOrderList,
  emptyServiceForm,
  emptyService,
  emptyServiceOption,
  emptyServiceOptions,
  status,
  types,
  confirmationMessages,
};

export default serviceUtils;
