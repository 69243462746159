import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { Section } from "../../../components/Section";
import generalUtils from "../../../utils/general-utils";
import { Container } from "./styles";
import Directories from "../../../components/Directories";
import theme from "../../../styles/theme";

export default function Reports() {
  const search = useLocation().search.replace("?search=", "");
  const directories = [
    { text: "CHECKLISTS", route: "checklists" },
    { text: "DIÁRIO DE OPERAÇÕES", route: "rdo" },
    { text: "ANÁLISE DE PLANOS", route: "analisedeplanos" },
  ];
  const [dirList, setDirList] = useState(directories);

  useEffect(() => {
    generalUtils.handleDirSearch({ search, directories, setDirList });
  }, [search]);

  return (
    <Section title="Relatórios">
      <Container theme={theme}>
        <Directories dirList={dirList} />
      </Container>
    </Section>
  );
}
