import useAsync from "../../useAsync";

import * as reportApi from "../../../services/reportApi";
import useToken from "../../useToken";

export default function usePlanAnalysisInfo() {
  const token = useToken();

  const {
    data: planAnalysisInfo,
    loading: planAnalysisInfoLoading,
    error: planAnalysisInfoError,
    act: getPlanAnalysisInfo,
  } = useAsync((data) => reportApi.getPlanAnalysisInfo(data, token), false);

  return {
    planAnalysisInfo,
    planAnalysisInfoLoading,
    planAnalysisInfoError,
    getPlanAnalysisInfo,
  };
}
