import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 58px;
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.COLORS.BACKGROUND_900};
  color: ${({ theme }) => theme.COLORS.GRAY_300};
  padding-right: 10px;
  margin-bottom: 14px;
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.COLORS.BACKGROUND_700};
  font-size: 18px;
  white-space: pre-wrap;
  position: absolute;
  left: 0;
  bottom: 0;

  & > svg {
    margin-left: 16px;
    color: ${({ theme }) => theme.COLORS.GRAY_100} !important;
    width: 22px;
    height: 22px;
    min-width: 22px;
    min-height: 22px;
  }

  & > div {
    width: 100%;
  }

  .select {
    text-overflow: ellipsis;

    span {
      margin: 0;
    }
  }

  .select > div > div > div:first-child div {
    max-width: 100%;
    overflow: hidden;
  }

  .select > div > div:first-child > div:last-child > div {
    padding: 0;
  }

  .placeholder {
    text-overflow: ellipsis;
  }

  input[type="text"] {
    color: ${({ theme }) => theme.COLORS.GRAY_100} !important;
  }

  @media (max-width: 767px) {
    margin-bottom: 10px;
    height: 46px;
    margin-bottom: 10px;
    font-size: 14px;
    padding-right: 8px;

    svg {
      margin-left: 12px;
      width: 20px;
      height: 20px;
      min-width: 20px;
      min-height: 20px;
    }

    .select {
      .placeholder {
        text-overflow: ellipsis;
      }

      svg {
        margin: 0;
      }

      .css-1by1pg0-indicatorContainer {
        padding: 0;
      }
    }
  }
`;
