import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
  margin-top: 20px;
  max-width: 190px;
  overflow: hidden;

  p {
    font-size: 14px;
    color: ${({ theme }) => theme.COLORS.GRAY_100};
    margin: 15px 0 0 5px;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  label {
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: ${({ enabled }) => (enabled ? "pointer" : "initial")};

    input {
      display: none;
    }

    svg {
      width: 30px;
      height: 30px;
      color: ${({ theme }) => theme.COLORS.GRAY_100};
    }
  }

  label:hover {
    svg {
      color: ${({ theme, enabled }) =>
        enabled ? theme.COLORS.WHITE : theme.COLORS.GRAY_100};
    }
  }
`;
