import useAsync from "../../useAsync";

import * as dpcApi from "../../../services/dpcApi";
import useToken from "../../useToken";

export default function useDpcCertificate() {
  const token = useToken();

  const {
    data: dpcCertificate,
    loading: dpcCertificateLoading,
    error: dpcCertificateError,
    act: getDpcCertificate,
  } = useAsync((data) => dpcApi.getCertificate(data, token), false);

  return {
    dpcCertificate,
    dpcCertificateLoading,
    dpcCertificateError,
    getDpcCertificate,
  };
}
