import { useParams } from "react-router-dom";

import { Section } from "../../../components/Section";
import toastUtils from "../../../utils/toast-utils";
import { useEffect, useState } from "react";
import dpcUtils from "../../../utils/dpc-utils";
import PdfViewer from "../../../components/PdfViewer";
import Loader from "../../../components/Loader";
import useDpcReport from "../../../hooks/api/dpc/useDpcReport";

export default function DpcReport() {
  const { reportId } = useParams();
  const { getDpcReport, dpcReportLoading } = useDpcReport();
  const [title, setTitle] = useState("Relatórios");
  const [report, setReport] = useState(dpcUtils.emptyCertificate);

  useEffect(() => {
    loadReportData();
  }, []);

  async function loadReportData() {
    try {
      const response = await getDpcReport(reportId);

      if (response) {
        setReport(response);
      }
      setTitle(`${title}/${response.name}`);
    } catch (error) {
      toastUtils.toaster({
        message: error.response.data,
        type: toastUtils.type.error,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    }
  }

  return (
    <Section title={title}>
      {dpcReportLoading ? (
        <Loader />
      ) : (
        <PdfViewer fileSource={report.fileSource} />
      )}
    </Section>
  );
}
