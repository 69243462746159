import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto 4px;
  padding: 0 5px;

  svg {
    width: 24px;
    height: 24px;
    cursor: pointer;
    color: ${({ theme, selected }) =>
      selected ? theme.COLORS.BLUE : theme.COLORS.GRAY_100};
  }

  svg:hover {
    color: ${({ theme }) => theme.COLORS.BLUE};
  }
`;

export const Counter = styled.div`
  min-width: 20px;
  height: 20px;
  border-radius: 10px;
  position: absolute;
  top: -5px;
  left: 15px;
  padding: 1px 3px 0 3px;
  background-color: ${({ theme }) => theme.COLORS.BLUE};
  color: ${({ theme }) => theme.COLORS.WHITE};
  border: solid 2px ${({ theme }) => theme.COLORS.WHITE};
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  vertical-align: center;
  font-size: 10px;
  font-weight: 700;
`;
