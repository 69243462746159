import { FiUser, FiMail, FiKey, FiPhone, FiPlus } from "react-icons/fi";
import {
  BsBriefcase,
  BsPersonVcard,
  BsHouse,
  BsPersonWorkspace,
  BsGenderAmbiguous,
  BsCalendarEvent,
} from "react-icons/bs";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import "dayjs/locale/pt-br";

import {
  Container,
  Form,
  Edit,
  FormsWrapper,
  Divider,
  Row,
  Address,
  AddButton,
  FullContact,
} from "../styles";
import theme from "../../../../styles/theme";
import Input from "../../../../components/forms/enrollment-form/Input";
import Button from "../../../../components/forms/enrollment-form/Button";
import Label from "../../../../components/forms/enrollment-form/Label";
import Select from "../../../../components/forms/enrollment-form/Select";
import { Section } from "../../../../components/Section";
import UserContext from "../../../../contexts/UserContext";
import EnrollmentContext from "../../../../contexts/EnrollmentContext";
import enrollmentUtils from "../../../../utils/enrollment-utils";
import useAdddress from "../../../../hooks/api/enrollment/useAddress";
import useEnrollment from "../../../../hooks/api/enrollment/useEnrollment";
import useSaveEnrollment from "../../../../hooks/api/enrollment/useSaveEnrollment";
import AddRole from "../../../../components/forms/enrollment-form/AddRole";
import Avatar from "../../../../components/forms/enrollment-form/Avatar";
import useUpdateUser from "../../../../hooks/api/user/useUpdateUser";
import useUserData from "../../../../hooks/api/user/useUserData";
import toastUtils from "../../../../utils/toast-utils";
import { ProfileContainer } from "./styles";
import DateTimePicker from "../../../../components/DateTimePicker";

export default function Profile() {
  const { userData, setUserData } = useContext(UserContext);
  const { enrollmentData, setEnrollmentData } = useContext(EnrollmentContext);
  const { userId } = useParams();
  const myProfile = userData.user.id === Number(userId);
  const title = myProfile ? "Perfil" : "Cadastro/Usuários/Perfil";
  const { getAddress } = useAdddress();
  const { getEnrollment } = useEnrollment();
  const { saveEnrollment, saveEnrollmentLoading } = useSaveEnrollment();
  const { updateUser, updateUserLoading } = useUpdateUser();
  const { getUserData } = useUserData();
  const [editing, setEditing] = useState(false);
  const [addingRole, setAddingRole] = useState(false);
  const [permissions, setPermissions] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [roles, setRoles] = useState([]);
  const [userForm, setUserForm] = useState(enrollmentUtils.emptyProfile.user);
  const [managerForm, setManagerForm] = useState(
    enrollmentUtils.emptyProfile.enrollment
  );
  const [allowed, setAllowed] = useState(
    userData.permission?.label === "MASTER" ||
      userData.permission?.label === "APROVADOR" ||
      userData.department?.label === "ADMINISTRATIVO"
  );
  const [submitted, setSubmitted] = useState(false);

  dayjs.locale("pt-br");

  useEffect(() => {
    if (!editing)
      setAllowed(
        userData.permission?.label === "MASTER" ||
          userData.permission?.label === "APROVADOR"
      );
  }, [managerForm.permission]);

  useEffect(() => {
    setEnrollmentData(managerForm);
    loadEnrollmentData();
  }, [userId]);

  async function loadEnrollmentData() {
    try {
      const response = await getEnrollment(userId);

      if (response) {
        setPermissions(response.permissions);
        setDepartments(response.departments);
        setRoles(response.roles);
        setUserForm(response.user);

        if (response.enrollment) {
          const data = {
            ...managerForm,
            ...enrollmentUtils.editIncomingProfile(response.enrollment),
          };
          setManagerForm(data);
          setEnrollmentData(data);
        } else {
          setManagerForm(enrollmentUtils.emptyProfile.enrollment);
        }
      }
    } catch (error) {
      toastUtils.toaster({
        message: error.response.data,
        type: toastUtils.type.error,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    }
  }

  function handleForm({ name, value, form, setForm }) {
    if (name === "cpf")
      return setForm({ ...form, [name]: enrollmentUtils.cpfMask(value) });

    if (name === "phone" || name === "emergencyContactPhone")
      return setForm({ ...form, [name]: enrollmentUtils.phoneMask(value) });

    if (name === "number")
      return setForm({ ...form, [name]: value.toString() });

    setForm({ ...form, [name]: value });
  }

  async function handleUserFormSubmit(e) {
    e.preventDefault();

    setSubmitted(true);

    try {
      await updateUser(userForm, userData.user.id);

      const response = await getUserData(userData.user.id);

      setUserData({ ...userData, user: response });

      setEditing(false);

      toastUtils.toaster({
        message: "Dados do usuário atualizados com sucesso!",
        type: toastUtils.type.success,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    } catch (error) {
      toastUtils.toaster({
        message: error.response.data,
        type: toastUtils.type.error,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    }
  }

  async function handleManagerFormSubmit(e) {
    e.preventDefault();

    setSubmitted(true);

    if (addingRole) return;

    try {
      // eslint-disable-next-line no-unused-vars
      const { enrollmentId, ...rest } = managerForm;
      const body = {
        ...rest,
        userId,
        cpf: enrollmentUtils.numberUnmask(managerForm.cpf),
        phone: enrollmentUtils.numberUnmask(managerForm.phone),
        emergencyContactPhone: enrollmentUtils.numberUnmask(
          managerForm.emergencyContactPhone
        ),
        cep: enrollmentUtils.numberUnmask(managerForm.cep),
        uf: managerForm.uf.value,
        creaUf: managerForm.crea ? managerForm.creaUf.value : "",
        gender: managerForm.gender.value,
        permission: managerForm.permission.value,
        role: {
          name: managerForm.role.label,
          id: isNaN(managerForm.role.value) ? 0 : managerForm.role.value,
        },
        department: managerForm.department.value,
        birthDate: new Date(managerForm.birthDate),
      };

      await saveEnrollment(body);

      setEditing(false);

      await getEnrollment(userData.user.id);

      toastUtils.toaster({
        message: "Informações salvas com sucesso!",
        type: toastUtils.type.success,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    } catch (error) {
      toastUtils.toaster({
        message: error.response.data,
        type: toastUtils.type.error,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    }
  }

  return (
    <Container theme={theme}>
      <Section title={title}>
        <ProfileContainer>
          <FormsWrapper>
            <Edit
              theme={theme}
              onClick={(e) => {
                e.preventDefault();

                if (editing) {
                  setManagerForm(enrollmentData);
                  setUserForm({
                    avatar: userData.user.avatar,
                    email: userData.user.email,
                    name: userData.user.name,
                  });
                } else if (!allowed && !myProfile) {
                  toastUtils.toaster({
                    message:
                      "Você não tem permissão para editar os dados deste usuário!",
                    type: toastUtils.type.error,
                    position: toastUtils.position.topCenter,
                    theme: toastUtils.theme.colored,
                  });

                  return;
                }

                setEditing(!editing);
              }}
            >
              {editing ? "Cancelar" : "Editar"}
            </Edit>

            <Form className="userForm" onSubmit={handleUserFormSubmit}>
              <Avatar
                handleForm={handleForm}
                userForm={userForm}
                setUserForm={setUserForm}
                enabled={editing && myProfile}
              />

              <Row>
                <span className="userName">
                  <Label id="name" text="Nome de Usuário" />
                  <Input
                    placeholder="Nome de usuário"
                    type="text"
                    id="name"
                    value={userForm.name}
                    icon={FiUser}
                    disabled={!(editing && myProfile)}
                    submitted={submitted}
                    onChange={(e) =>
                      handleForm({
                        name: e.target.id,
                        value: e.target.value,
                        form: userForm,
                        setForm: setUserForm,
                      })
                    }
                  />
                </span>
              </Row>

              <Row>
                <span className="email">
                  <Label id="email" text="E-mail" />
                  <Input
                    placeholder="E-mail"
                    type="email"
                    id="email"
                    value={userForm.email}
                    icon={FiMail}
                    disabled={!(editing && myProfile)}
                    submitted={submitted}
                    onChange={(e) =>
                      handleForm({
                        name: e.target.id,
                        value: e.target.value,
                        form: userForm,
                        setForm: setUserForm,
                      })
                    }
                  />
                </span>
              </Row>

              <Button
                title="Salvar"
                enabled={editing && myProfile && !updateUserLoading}
              />
            </Form>

            <Divider theme={theme} />

            <Form className="managerForm" onSubmit={handleManagerFormSubmit}>
              <Row>
                <span className="permission">
                  <Label id="permission" text="Perfil de Acesso*" />
                  <Select
                    id="permission"
                    icon={FiKey}
                    placeholder={"Selecione..."}
                    enabled={editing && allowed}
                    list={permissions}
                    selected={managerForm.permission}
                    required
                    submitted={submitted}
                    form={managerForm}
                    setForm={setManagerForm}
                    handleForm={handleForm}
                  />
                </span>
              </Row>

              <Row>
                <span className="department">
                  <Label id="department" text="Setor*" />
                  <Select
                    id="department"
                    icon={BsPersonWorkspace}
                    placeholder={"Selecione..."}
                    enabled={editing && allowed}
                    list={departments}
                    selected={managerForm.department}
                    required
                    submitted={submitted}
                    form={managerForm}
                    setForm={setManagerForm}
                    handleForm={handleForm}
                  />
                </span>
              </Row>

              <Row>
                <span className="role">
                  <Label id="role" text="Função*" />
                  <Select
                    id="role"
                    placeholder={"Selecione..."}
                    icon={BsBriefcase}
                    enabled={editing && allowed}
                    list={roles}
                    selected={managerForm.role}
                    required
                    submitted={submitted}
                    form={managerForm}
                    setForm={setManagerForm}
                    handleForm={handleForm}
                  />
                </span>

                <span className="add-role">
                  <AddButton
                    theme={theme}
                    disabled={!(editing && allowed)}
                    onClick={() => setAddingRole(true)}
                  >
                    <FiPlus />
                  </AddButton>
                </span>
              </Row>

              <Row>
                <span className="fullName">
                  <Label id="name" text="Nome Completo*" />
                  <Input
                    id="name"
                    placeholder="Nome completo"
                    type="text"
                    icon={FiUser}
                    disabled={!(editing && allowed)}
                    required
                    submitted={submitted}
                    value={managerForm.name}
                    pattern={"^[a-zA-ZáàâãéèêíïóôõöúçñÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇÑ ]+$"}
                    onChange={(e) =>
                      handleForm({
                        name: e.target.id,
                        value: e.target.value,
                        form: managerForm,
                        setForm: setManagerForm,
                      })
                    }
                  />
                </span>
              </Row>

              <Row>
                <span className="cpf">
                  <Label id="cpf" text="CPF*" />
                  <Input
                    id="cpf"
                    placeholder="CPF"
                    type="text"
                    icon={BsPersonVcard}
                    disabled={!(editing && allowed)}
                    required
                    submitted={submitted}
                    value={managerForm.cpf}
                    maxLength="14"
                    minLength="14"
                    pattern="^(?!000\.000\.000-00|00\.0\.0\.00\.000|00000000000)(\d{3}\.\d{3}\.\d{3}-\d{2}|\d{11})$"
                    onChange={(e) =>
                      handleForm({
                        name: e.target.id,
                        value: e.target.value,
                        form: managerForm,
                        setForm: setManagerForm,
                      })
                    }
                  />
                </span>

                <span className="gender">
                  <Label id="gender" text="SEXO*" />
                  <Select
                    id="gender"
                    placeholder={"Selecione..."}
                    icon={BsGenderAmbiguous}
                    enabled={editing && allowed}
                    list={enrollmentUtils.genderList}
                    selected={managerForm.gender}
                    required
                    submitted={submitted}
                    form={managerForm}
                    setForm={setManagerForm}
                    handleForm={handleForm}
                  />
                </span>
              </Row>

              <Row>
                <span className="crea">
                  <Label id="crea" text="CREA" />
                  <Input
                    id="crea"
                    placeholder="CREA"
                    type="text"
                    icon={BsPersonVcard}
                    disabled={!(editing && allowed)}
                    submitted={submitted}
                    value={managerForm.crea}
                    pattern="^[\d-]+$"
                    onChange={(e) =>
                      handleForm({
                        name: e.target.id,
                        value: e.target.value,
                        form: managerForm,
                        setForm: setManagerForm,
                      })
                    }
                  />
                </span>

                <span className="creaUf">
                  <Label
                    id="creaUf"
                    text={`Estado${managerForm.crea ? "*" : ""}`}
                  />
                  <Select
                    id="creaUf"
                    icon={BsHouse}
                    placeholder={"Selecione..."}
                    enabled={editing && allowed}
                    list={enrollmentUtils.states}
                    selected={managerForm.creaUf}
                    required={managerForm.crea ? true : false}
                    submitted={submitted}
                    form={managerForm}
                    setForm={setManagerForm}
                    handleForm={handleForm}
                  />
                </span>
              </Row>

              <Row>
                <span className="birthDate">
                  <Label id="birthDate" text="Data de Nascimento" />
                  <DateTimePicker
                    id="birthDate"
                    type="date"
                    icon={BsCalendarEvent}
                    disabled={!(editing && allowed)}
                    value={
                      managerForm.birthDate
                        ? dayjs(managerForm.birthDate).format("DD/MM/YYYY")
                        : null
                    }
                    form={managerForm}
                    setForm={setManagerForm}
                  />
                </span>

                <span className="phone">
                  <Label id="phone" text="Telefone*" />
                  <Input
                    id="phone"
                    placeholder="Telefone"
                    type="tel"
                    icon={FiPhone}
                    disabled={!(editing && allowed)}
                    required
                    submitted={submitted}
                    value={managerForm.phone}
                    pattern="^\(\d{2}\)\s(?:9)?\d{4}-\d{4}$"
                    onChange={(e) =>
                      handleForm({
                        name: e.target.id,
                        value: e.target.value,
                        form: managerForm,
                        setForm: setManagerForm,
                      })
                    }
                  />
                </span>
              </Row>

              <Row>
                <span>
                  <Label text="Contato de Emergência*" />
                  <FullContact>
                    <Row>
                      <span className="emergencyContactName">
                        <Input
                          id="emergencyContactName"
                          placeholder="Nome"
                          type="text"
                          icon={FiUser}
                          disabled={!(editing && allowed)}
                          required
                          submitted={submitted}
                          value={managerForm.emergencyContactName}
                          pattern={
                            "^[a-zA-ZáàâãéèêíïóôõöúçñÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇÑ ]+$"
                          }
                          onChange={(e) =>
                            handleForm({
                              name: e.target.id,
                              value: e.target.value,
                              form: managerForm,
                              setForm: setManagerForm,
                            })
                          }
                        />
                      </span>

                      <span className="emergencyContactPhone">
                        <Label text="Telefone de Emergência*" />
                        <Input
                          id="emergencyContactPhone"
                          placeholder="Telefone"
                          type="tel"
                          icon={FiPhone}
                          disabled={!(editing && allowed)}
                          required
                          submitted={submitted}
                          value={managerForm.emergencyContactPhone}
                          pattern="^\(\d{2}\)\s(?:9)?\d{4}-\d{4}$"
                          onChange={(e) =>
                            handleForm({
                              name: e.target.id,
                              value: e.target.value,
                              form: managerForm,
                              setForm: setManagerForm,
                            })
                          }
                        />
                      </span>
                    </Row>
                  </FullContact>
                </span>
              </Row>

              <Address>
                <Row>
                  <span className="cep">
                    <Label id="cep" text="CEP*" />
                    <Input
                      id="cep"
                      placeholder="CEP"
                      type="text"
                      icon={BsHouse}
                      disabled={!(editing && allowed)}
                      required
                      submitted={submitted}
                      value={managerForm.cep}
                      maxLength="9"
                      minLength="9"
                      pattern="\d{5}-\d{3}"
                      onChange={(e) =>
                        enrollmentUtils.handleCep(
                          e,
                          managerForm,
                          setManagerForm,
                          getAddress
                        )
                      }
                    />
                  </span>

                  <span className="uf">
                    <Label id="uf" text="Estado*" />
                    <Select
                      id="uf"
                      icon={BsHouse}
                      placeholder={"Selecione..."}
                      enabled={editing && allowed}
                      list={enrollmentUtils.states}
                      selected={managerForm.uf}
                      required
                      submitted={submitted}
                      form={managerForm}
                      setForm={setManagerForm}
                      handleForm={handleForm}
                    />
                  </span>
                </Row>

                <Row>
                  <span className="city">
                    <Label id="city" text="Cidade*" />
                    <Input
                      id="city"
                      placeholder="Cidade"
                      type="text"
                      icon={BsHouse}
                      disabled={!(editing && allowed)}
                      required
                      submitted={submitted}
                      value={managerForm.city}
                      pattern={"^[a-zA-ZáàâãéèêíïóôõöúçñÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇÑ., ]+$"}
                      onChange={(e) =>
                        handleForm({
                          name: e.target.id,
                          value: e.target.value,
                          form: managerForm,
                          setForm: setManagerForm,
                        })
                      }
                    />
                  </span>

                  <span className="neighborhood*">
                    <Label id="neighborhood" text="Bairro*" />
                    <Input
                      id="neighborhood"
                      placeholder="Bairro"
                      type="text"
                      icon={BsHouse}
                      disabled={!(editing && allowed)}
                      required
                      submitted={submitted}
                      value={managerForm.neighborhood}
                      pattern={"^[a-zA-ZáàâãéèêíïóôõöúçñÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇÑ., ]+$"}
                      onChange={(e) =>
                        handleForm({
                          name: e.target.id,
                          value: e.target.value,
                          form: managerForm,
                          setForm: setManagerForm,
                        })
                      }
                    />
                  </span>
                </Row>

                <Row>
                  <span className="street">
                    <Label id="street" text="Logradouro*" />
                    <Input
                      id="street"
                      placeholder="Logradouro"
                      type="text"
                      icon={BsHouse}
                      disabled={!(editing && allowed)}
                      required
                      submitted={submitted}
                      value={managerForm.street}
                      pattern={
                        "^[a-zA-ZáàâãéèêíïóôõöúçñÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇÑ0-9., ]+$"
                      }
                      onChange={(e) =>
                        handleForm({
                          name: e.target.id,
                          value: e.target.value,
                          form: managerForm,
                          setForm: setManagerForm,
                        })
                      }
                    />
                  </span>

                  <span className="number">
                    <Label id="number" text="Número*" />
                    <Input
                      id="number"
                      placeholder="Número"
                      type="number"
                      icon={BsHouse}
                      disabled={!(editing && allowed)}
                      required
                      submitted={submitted}
                      value={managerForm.number}
                      onChange={(e) =>
                        handleForm({
                          name: e.target.id,
                          value: e.target.value,
                          form: managerForm,
                          setForm: setManagerForm,
                        })
                      }
                    />
                  </span>
                </Row>

                <Row>
                  <span className="complement">
                    <Label id="complement" text="Complemento (opcional)" />
                    <Input
                      id="complement"
                      placeholder="Complemento"
                      type="text"
                      icon={BsHouse}
                      disabled={!(editing && allowed)}
                      submitted={submitted}
                      value={managerForm.complement}
                      onChange={(e) =>
                        handleForm({
                          name: e.target.id,
                          value: e.target.value,
                          form: managerForm,
                          setForm: setManagerForm,
                        })
                      }
                    />
                  </span>
                </Row>
              </Address>

              <Button
                title="Salvar"
                enabled={editing && allowed && !saveEnrollmentLoading}
              />
            </Form>
          </FormsWrapper>
        </ProfileContainer>
      </Section>

      <AddRole
        managerForm={managerForm}
        setManagerForm={setManagerForm}
        handleForm={handleForm}
        roles={roles}
        setRoles={setRoles}
        addingRole={addingRole}
        setAddingRole={setAddingRole}
      />
    </Container>
  );
}
