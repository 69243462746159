import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";

import { Section } from "../../../components/Section";

import theme from "../../../styles/theme";
import Directories from "../../../components/Directories";
import generalUtils from "../../../utils/general-utils";
import { Container } from "../styles";

export default function Services() {
  const search = useLocation().search.replace("?search=", "");
  const directories = [
    { text: "SOLICITAÇÃO INICIAL", route: "solicitados" },
    { text: "ANÁLISE INTERNA", route: "analise" },
    { text: "SERVIÇO AUTORIZADO", route: "autorizados" },
    { text: "ORDEM DE SERVIÇO", route: "os" },
    { text: "SERVIÇO INTERROMPIDO", route: "interrompidos" },
  ];
  const [dirList, setDirList] = useState(directories);

  useEffect(() => {
    generalUtils.handleDirSearch({ search, directories, setDirList });
  }, [search]);

  return (
    <Section title="Serviços">
      <Container theme={theme}>
        <Directories dirList={dirList} />
      </Container>
    </Section>
  );
}
