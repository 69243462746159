import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import generalUtils from "../../../../utils/general-utils";
import { Section } from "../../../../components/Section";
import { Container } from "../styles";
import theme from "../../../../styles/theme";
import Directories from "../../../../components/Directories";

export default function DocumentAnalyses() {
  const search = useLocation().search.replace("?search=", "");

  const directories = [{ text: "ATRIBUÍDAS", route: "atribuidas" }];

  const [dirList, setDirList] = useState(directories);

  useEffect(() => {
    generalUtils.handleDirSearch({ search, directories, setDirList });
  }, [search]);

  return (
    <Section title="Certificados/Análises Documentais">
      <Container theme={theme}>
        <Directories dirList={dirList} />
      </Container>
    </Section>
  );
}
