import useAsync from "../../useAsync";

import * as reportApi from "../../../services/reportApi";
import useToken from "../../useToken";

export default function useSavePlanAnalysis() {
  const token = useToken();

  const {
    loading: savePlanAnalysisLoading,
    error: savePlanAnalysisError,
    act: savePlanAnalysis,
  } = useAsync((data) => reportApi.postPlanAnalysis(data, token), false);

  return {
    savePlanAnalysisLoading,
    savePlanAnalysisError,
    savePlanAnalysis,
  };
}
