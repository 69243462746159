import useAsync from "../../useAsync";

import * as dpcApi from "../../../services/dpcApi";
import useToken from "../../useToken";

export default function useDpcReports() {
  const token = useToken();

  const {
    data: dpcReports,
    loading: dpcReportsLoading,
    error: dpcReportsError,
    act: getDpcReports,
  } = useAsync((data) => dpcApi.getReports(data, token), false);

  return {
    dpcReports,
    dpcReportsLoading,
    dpcReportsError,
    getDpcReports,
  };
}
