import useAsync from "../../useAsync";

import * as notificationApi from "../../../services/notificationApi";
import useToken from "../../useToken";

export default function useDeleteNotification() {
  const token = useToken();

  const {
    loading: deleteNotificationLoading,
    error: deleteNotificationError,
    act: deleteNotification,
  } = useAsync(
    (data) => notificationApi.deleteNotification(data, token),
    false
  );

  return {
    deleteNotificationLoading,
    deleteNotificationError,
    deleteNotification,
  };
}
