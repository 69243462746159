import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  form {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 30px 0 0 0;
    width: 100%;
    position: relative;
    margin-bottom: 130px;
  }

  h3 {
    margin-bottom: 10px;
    font-size: 26px;
    font-weight: 400;
    margin-top: 0;
    color: ${({ theme }) => theme.COLORS.GRAY_100};
  }

  .textarea {
    height: 160px !important;
  }

  @media (max-width: 767px) {
    margin-top: 20px;

    h3 {
      font-size: 22px;
    }

    .responsible {
      flex-direction: column;

      span {
        margin: 0;
      }
    }
  }
`;

export const SchedulesTitle = styled.div`
  display: flex;
  align-items: center;

  svg {
    font-size: 20px;
    color: ${({ theme }) => theme.COLORS.GRAY_100};
    margin: 4px 0px 8px 10px;
    cursor: pointer;
  }
`;

export const ProductSelectorWrapper = styled.span`
  div {
    height: 36px;
    display: flex;
    align-items: center;
    margin-left: 10px;
  }

  svg {
    color: ${({ theme }) => theme.COLORS.ORANGE};
    font-size: 16px;
    margin-right: 10px;
    cursor: pointer;
  }

  h5 {
    color: ${({ theme }) => theme.COLORS.GRAY_100};
    font-size: 20px;
    font-weight: 400;
    margin: 0;
  }
`;

export const ProductComment = styled.input`
  width: calc(100% - ${({ editing }) => (editing ? "36px" : "10px")});
  text-overflow: ellipsis;
  margin-bottom: 10px;
  margin-left: ${({ editing }) => (editing ? "36px" : "10px")};
  margin-bottom: 20px;

  color: ${({ theme, disabled }) =>
    disabled ? theme.COLORS.GRAY_100 : theme.COLORS.WHITE};
  background: transparent;
  border: 0;
  border-bottom: 1px solid ${({ theme }) => theme.COLORS.GRAY_300};
  font-size: 18px;

  &:placeholder {
    color: ${({ theme }) => theme.COLORS.GRAY_300};
  }

  &:focus {
    box-shadow: 0 0 0 0;
    border: 0 none;
    border-bottom: 1px solid ${({ theme }) => theme.COLORS.GRAY_300};
    outline: 0;
  }

  &:autofill {
    box-shadow: 0 0 0 30px ${({ theme }) => theme.COLORS.BACKGROUND_900} inset;
    color: ${({ theme }) => theme.COLORS.WHITE}!important;
    border-radius: 0 10px 10px 0;
  }

  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px ${({ theme }) => theme.COLORS.BACKGROUND_900}
      inset;
    -webkit-text-fill-color: ${({ theme }) => theme.COLORS.WHITE}!important;
  }

  @media (max-width: 767px) {
    width: calc(100% - 30px);
    font-size: 16px;
  }
`;

export const ChecklistsWrapper = styled.div`
  width: 100%;
  margin-bottom: 30px;

  h5 {
    margin: 0 !important;
    font-weight: 400 !important;
    font-size: 16px;
  }
`;

export const AssignedItem = styled.div`
  img {
    width: 38px;
    height: 38px;
    margin-right: 10px;
  }

  span {
    display: flex;
    align-items: center;
  }
`;
