import styled from "styled-components";
import { Link } from "react-router-dom";

export const Container = styled.header`
  position: fixed;
  top: 0;
  left: 250px;
  z-index: 10;
  width: calc(100% - 250px);
  height: 105px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: ${({ theme }) => theme.COLORS.BACKGROUND_700};
  display: flex;
  justify-content: space-between;
  background: ${({ theme }) => theme.COLORS.WHITE};

  & > span {
    position: absolute;
    display: flex;
    align-items: center;
    right: 15px;
    height: 100%;
  }

  .right {
    right: 15px;

    @media (max-width: 767px) {
      right: 5px;
    }
  }

  .left {
    left: 15px;

    @media (max-width: 767px) {
      left: 5px;
    }
  }

  svg {
    font-size: 30px;
    color: ${({ theme }) => theme.COLORS.GRAY_100};
  }

  @media (max-width: 1023px) {
    left: 0;
    width: 100%;
    min-width: 0;
    max-width: 1023px;
  }

  @media (max-width: 767px) {
    height: 80px;
    padding: 0 40px;

    span {
      right: 5px;
    }

    img {
      max-width: 160px;
    }
  }
`;

export const Profile = styled(Link)`
  display: flex;
  align-items: center;
  margin-left: 70px;

  img {
    width: 240px;
  }

  div {
    display: flex;
    flex-direction: column;
    line-height: 24px;

    span {
      font-size: 14px;
      color: ${({ theme }) => theme.COLORS.GRAY_100};
    }

    strong {
      font-size: 18px;
      color: ${({ theme }) => theme.COLORS.GRAY_300};
    }
  }

  @media (max-width: 1023px) {
    margin: 0 auto;
  }
`;

export const Logout = styled.button`
  border: none;
  background: none;
  height: 30px;
  margin: auto 0;
  cursor: pointer;
  transition-duration: 100ms !important;

  svg,
  p {
    transition-duration: 100ms !important;
  }

  p {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
      "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
      "Helvetica Neue", sans-serif;
    margin: 0;
    font-weight: 700;
    color: ${({ theme }) => theme.COLORS.GRAY_100};
  }

  &:hover {
    svg,
    p {
      color: ${({ theme }) => theme.COLORS.BLUE};
    }
  }
`;

export const Back = styled.button`
  border: none;
  background: none;
  height: 30px;
  margin: auto 0;
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};

  svg {
    opacity: ${({ disabled }) => (disabled ? "30%" : "100%")};
  }

  svg:hover {
    color: ${({ theme, disabled }) =>
      disabled ? theme.COLORS.GRAY_100 : theme.COLORS.BLUE};
  }

  @media (max-width: 767px) {
    left: 0;
  }
`;

export const MenuButton = styled.button`
  display: none;
  border: none;
  background: none;
  height: 30px;
  margin: auto 0;
  cursor: pointer;
  right: 16px;

  svg {
    color: ${({ theme, displayMenu }) =>
      displayMenu ? theme.COLORS.BLUE : theme.COLORS.GRAY_100};
  }

  svg:hover {
    color: ${({ theme }) => theme.COLORS.BLUE};
  }

  @media (max-width: 1023px) {
    display: initial;
  }

  @media (max-width: 767px) {
    right: 4px;
  }
`;
