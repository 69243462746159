import api from "./api";

export async function getRequestOptions(token) {
  const response = await api.get("/request/options", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

export async function getRequests({ order, skip, take, types, filter }, token) {
  const response = await api.get(
    `/request/many?order=${order}&skip=${skip}&take=${take}&types=${types}&filter=${filter}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
}

export async function getRequestsByStatus(
  { order, skip, take, statusId },
  token
) {
  const response = await api.get(
    `/request/many/status/${statusId}?order=${order}&skip=${skip}&take=${take}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
}

export async function getRequest({ serviceId, status }, token) {
  const response = await api.get(`/request/${serviceId}?status=${status}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

export async function getRequestHistory(serviceId, token) {
  const response = await api.get(`/request/history/${serviceId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

export async function postRequest(body, token) {
  const response = await api.post("/request", body, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

export async function deleteRequest({ serviceId, status }, token) {
  const response = await api.delete(`/request/${serviceId}?status=${status}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}
