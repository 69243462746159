const certificateEmptyFilters = {
  companies: [{ value: 0, label: "TODOS" }],
  vessels: [{ value: 0, label: "TODOS" }],
};

const certificateEmptyDisplayParams = {
  company: { label: "", value: 0 },
  vessel: { label: "", value: 0 },
};

const emptyCertificate = {
  id: 0,
  vessel: { id: 0, name: "" },
  company: { id: 0, name: "" },
  name: "",
  fileSource: "",
};

const dpcUtils = {
  certificateEmptyFilters,
  certificateEmptyDisplayParams,
  emptyCertificate,
};

export default dpcUtils;
