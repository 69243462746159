import useAsync from "../../useAsync";

import * as reportApi from "../../../services/reportApi";
import useToken from "../../useToken";

export default function usePlanAnalysisFilters() {
  const token = useToken();

  const {
    data: planAnalysisFilters,
    loading: planAnalysisFiltersLoading,
    error: planAnalysisFiltersError,
    act: getPlanAnalysisFilters,
  } = useAsync(() => reportApi.getPlanAnalysisFilters(token), false);

  return {
    planAnalysisFilters,
    planAnalysisFiltersLoading,
    planAnalysisFiltersError,
    getPlanAnalysisFilters,
  };
}
