import { useContext, useEffect, useState } from "react";
import dayjs from "dayjs";
import "dayjs/locale/pt-br";

import theme from "../../../../styles/theme";
import { Container, Row } from "./styles";
import ReportDatePicker from "../ReportDatePicker";
import ChecklistSelect from "../../../../components/forms/report-form/ChecklistSelect";
import UserContext from "../../../../contexts/UserContext";
import { Tooltip } from "../../../../pages/Home/Reports/PlanAnalysis/styles";

export default function PlanAnalysisSection({
  section,
  enabled,
  setAnalysisData,
  options,
  sectionIndex,
  handleReview,
}) {
  let counter = 1;

  return (
    <Container theme={theme}>
      <Row className="title" theme={theme}>
        <span className="section">{`Seção ${section.sectionKey}: ${section.name}`}</span>
      </Row>

      <Row className="subtitle" theme={theme}>
        <span className="index">Item</span>
        <span className="reference">Ref.</span>
        <span className="description">Comentário</span>
        <span className="date desktop">Ação Tomada</span>
        <span className="date mobile">A. Tom.</span>
        <span className="status desktop">Situação</span>
        <span className="status mobile">Sit.</span>
      </Row>

      {section.items.map((item, index) => {
        return (
          <PlanAnalysisItem
            key={index}
            item={item}
            index={index}
            sectionKey={section.sectionKey}
            sectionIndex={sectionIndex}
            counter={counter++}
            enabled={enabled}
            setAnalysisData={setAnalysisData}
            options={options}
            handleReview={handleReview}
          />
        );
      })}
    </Container>
  );
}

function PlanAnalysisItem({
  item,
  index,
  sectionKey,
  sectionIndex,
  counter,
  enabled,
  setAnalysisData,
  options,
  handleReview,
}) {
  const { userData } = useContext(UserContext);
  const [deadline, setDeadline] = useState({
    date: item.deadline ? new Date(item.deadline) : null,
  });
  const [showTooltip, setShowTooltip] = useState(false);

  useEffect(() => {
    handleDeadline();
  }, [deadline]);

  useEffect(() => {
    if (enabled) return;

    setDeadline({
      date: item.deadline ? new Date(item.deadline) : null,
    });
  }, [enabled]);

  function handleSelect({ value }) {
    setAnalysisData((prevData) => ({
      ...prevData,
      sections: prevData.sections.map((section, i) => {
        if (i === sectionIndex) {
          return {
            ...section,
            items: section.items.map((sectionItem, j) =>
              j === index
                ? {
                    ...sectionItem,
                    status: value,
                    user: {
                      name: userData.user.name,
                      id: userData.enrollmentId,
                    },
                  }
                : sectionItem
            ),
          };
        }
        return section;
      }),
    }));

    handleReview(sectionKey.charCodeAt(0) - 65);
  }

  function handleDeadline() {
    setAnalysisData((prevData) => ({
      ...prevData,
      sections: prevData.sections.map((section, i) => {
        if (i === sectionIndex) {
          return {
            ...section,
            items: section.items.map((sectionItem, j) =>
              j === index
                ? {
                    ...sectionItem,
                    deadline: deadline.date,
                    user: {
                      name: userData.user.name,
                      id: userData.enrollmentId,
                    },
                  }
                : sectionItem
            ),
          };
        }
        return section;
      }),
    }));

    handleReview(sectionKey.charCodeAt(0) - 65);
  }

  return (
    <>
      <Row
        key={sectionKey}
        theme={theme}
        enabled={enabled}
        onMouseEnter={() =>
          item.user.id ? setShowTooltip(true) : setShowTooltip(false)
        }
        onMouseLeave={() => setShowTooltip(false)}
      >
        <Tooltip
          theme={theme}
          show={showTooltip}
        >{`Alterado por ${item.user.name}`}</Tooltip>
        <span className="index">{`${sectionKey}${counter}`}</span>
        <span className="reference">{item.reference || "-"}</span>
        <span className="description">
          <p>{item.comment}</p>
        </span>
        <span className="date">
          <p>
            {item.status.label === "CUMPRIDO" ? "Sanado em" : "Cumprir até"}
          </p>
          <ReportDatePicker
            id="date"
            type="date"
            disabled={!enabled}
            required
            value={
              deadline.date ? dayjs(deadline.date).format("DD/MM/YYYY") : null
            }
            minDate={
              item.status.label !== "CUMPRIDO"
                ? new Date(dayjs().startOf("day"))
                : null
            }
            maxDate={item.status.label === "CUMPRIDO" ? new Date() : null}
            form={deadline}
            setForm={setDeadline}
          />
        </span>
        <span className="status">
          <ChecklistSelect
            id={item.id}
            placeholder={enabled ? "Selecione..." : ""}
            enabled={enabled}
            list={options}
            selected={item.status}
            required
            handleForm={handleSelect}
          />
        </span>
      </Row>
    </>
  );
}
