import styled from "styled-components";

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

export const BoxWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: 0s;
  color: ${({ theme, disabled, checked }) =>
    disabled
      ? theme.COLORS.GRAY_200
      : checked
      ? theme.COLORS.WHITE
      : theme.COLORS.GRAY_100};

  input[type="checkbox"] {
    -webkit-appearance: none; /* Remove a aparência padrão do checkbox no Safari/Chrome */
    -moz-appearance: none; /* Remove a aparência padrão do checkbox no Firefox */
    appearance: none; /* Remove a aparência padrão do checkbox em outros navegadores */
    width: 20px;
    height: 20px;
    min-width: 20px;
    border: 2px solid ${({ theme }) => theme.COLORS.GRAY_100};
    border-radius: 50%;
    margin-right: 10px;
    background-color: ${({ theme }) => theme.COLORS.BACKGROUND_100};
    outline: none;
    transition: border-color 0.2s;
    cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
    z-index: 1;
  }

  input[type="checkbox"]:checked {
    background-color: ${({ theme }) => theme.COLORS.ORANGE};
    border: 3px solid ${({ theme }) => theme.COLORS.WHITE};
  }

  input[type="checkbox"]:hover {
    border-color: ${({ theme, disabled, checked }) =>
      disabled
        ? checked
          ? theme.COLORS.WHITE
          : theme.COLORS.GRAY_100
        : checked
        ? theme.COLORS.GRAY_100
        : theme.COLORS.ORANGE};
  }
`;
