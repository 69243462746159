import api from "./api";

export async function getCertificates({ skip, take, filter }, token) {
  const response = await api.get(
    `/certificate/many?skip=${skip}&take=${take}&filter=${filter}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
}

export async function getCertificate(certificateId, token) {
  const response = await api.get(`/certificate/${certificateId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

export async function getCertificateFilters(token) {
  const response = await api.get("/certificate/filters", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

export async function getReports({ skip, take, filter }, token) {
  const response = await api.get(
    `/certificate/report/many?skip=${skip}&take=${take}&filter=${filter}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
}

export async function getReport(reportId, token) {
  const response = await api.get(`/certificate/report/${reportId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}
