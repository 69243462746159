import useAsync from "../../useAsync";

import * as jasperApi from "../../../services/jasperApi";
import useToken from "../../useToken";

export default function useJasperPlanAnalysis() {
  const token = useToken();

  const {
    loading: jasperPlanAnalysisLoading,
    error: jasperPlanAnalysisError,
    act: saveJasperPlanAnalysis,
  } = useAsync((data) => jasperApi.postJasperPlanAnalysis(data, token), false);

  return {
    jasperPlanAnalysisLoading,
    jasperPlanAnalysisError,
    saveJasperPlanAnalysis,
  };
}
