import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 20px 0 30px;
  border: 2px solid ${({ theme }) => theme.COLORS.GRAY_100};

  .title,
  .subtitle {
    font-weight: 700;
    color: ${({ theme }) => theme.COLORS.WHITE};

    span {
      align-items: center;
    }
  }

  .title {
    min-height: 30px;
    border-top: 0;
    background-color: ${({ theme }) => theme.COLORS.BACKGROUND_600};

    span {
      font-size: 15px;
    }
  }

  .subtitle {
    min-height: 24px;
    background-color: ${({ theme }) => theme.COLORS.BACKGROUND_800};

    span {
      font-size: 12px;
    }
  }

  @media (max-width: 1023px) {
    .title span {
      font-size: 14px;
    }

    .subtitle span {
      font-size: 12px;
    }
  }

  @media (max-width: 767px) {
    .title span {
      font-size: 10px;
    }

    .subtitle span {
      font-size: 10px;
    }
  }
`;

export const Row = styled.span`
  width: 100%;
  min-height: 40px;
  display: flex;
  flex-direction: row;
  border-top: 2px solid ${({ theme }) => theme.COLORS.GRAY_100};
  color: ${({ theme }) => theme.COLORS.WHITE};
  position: relative;

  & > span {
    display: flex;
    align-items: center;
    border-left: 1px solid ${({ theme }) => theme.COLORS.GRAY_100};
    justify-content: center;
    padding: 5px;
    flex-wrap: wrap;
    word-break: break-word;
    font-size: 14px;

    &:first-child {
      border-left: none;
    }
  }

  .index {
    padding: 0;
    width: 5%;
  }

  .description {
    width: 53%;
    justify-content: left;

    p {
      font-size: 14px;
      margin: 0;
      max-width: 94%;
    }

    span {
      flex-wrap: nowrap;
      width: ${({ enabled }) => (enabled ? "90%" : "100%")};
      padding-left: ${({ isSubitem }) => (isSubitem ? "20px" : "5")};
      margin-top: 7px;
      justify-content: space-between;
    }

    svg {
      margin-left: 10px;
      margin-top: 2px;
      cursor: pointer;
      width: 14px;
      height: 14px;
    }
  }

  .reference {
    width: 14%;
    text-align: center;
  }

  .date {
    width: 14%;
    text-align: center;

    p {
      margin: 0 0 5px;
      font-size: 12px;
    }

    input {
      cursor: pointer;
    }
  }

  .check-box {
    width: 6%;
  }

  .status {
    width: 14%;
    padding: 0;
    text-align: center;
  }

  .add-item {
    align-items: center;

    cursor: pointer;

    svg {
      margin: 0 5px 0 0;
    }

    p {
      margin: 0;
      font-weight: 700;
    }
  }

  @media (max-width: 1023px) {
    .description {
      font-size: 14px;
    }
  }

  @media (max-width: 767px) {
    min-height: 30px;

    span {
      font-size: 10px;
      padding: 2px;
    }

    .reference {
      width: 14;
      min-width: 14%;
      max-width: 14%;
    }

    .descriptio p {
      font-size: 10px;
    }

    .date {
      width: 18%;
      min-width: 18%;
      max-width: 18%;

      input {
        font-size: 10px;
        padding: 0;
      }
    }

    .status {
      min-width: 17%;
      max-width: 17%;
      padding: 2px;
    }

    .index {
      width: 8%;
      min-width: 8%;
      max-width: 8%;
      padding: 2px;
      justify-content: center;
    }
  }
`;
