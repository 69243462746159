import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { BiFilter } from "react-icons/bi";
import { CgReorder } from "react-icons/cg";
import { BsPlusCircleFill } from "react-icons/bs";

import { Section } from "../../../components/Section";
import theme from "../../../styles/theme";
import reportUtils from "../../../utils/report-utils";
import { ParamsWrapper } from "../Enrollment/styles";
import Select from "../../../components/forms/report-form/Select";
import FilterSelector from "../../../components/FilterSelector";
import { AddListItem, Container, ListItem } from "../styles";
import usePlanAnalysisReports from "../../../hooks/api/report/usePlanAnalysisReports";
import usePlanAnalysisFilters from "../../../hooks/api/report/usePlanAnalysisFilters";
import toastUtils from "../../../utils/toast-utils";
import Loader from "../../../components/Loader";
import ListWrapper from "../../../components/ListWrapper";
import noPicProfile from "../../../assets/no-pic-profile.png";

export default function PlanAnalyses() {
  const navigate = useNavigate();
  const responseLength = 20;
  const { getPlanAnalysisReports, planAnalysisReportsLoading } =
    usePlanAnalysisReports();
  const { getPlanAnalysisFilters } = usePlanAnalysisFilters();
  const [reports, setReports] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const [filters, setFilters] = useState(reportUtils.planAnalysisEmptyFilters);
  const [filter, setFilter] = useState({});
  const [displayParams, setDisplayParams] = useState(
    reportUtils.planAnalysisEmptyDisplayParams
  );
  const search = useLocation().search.replace("?search=", "");
  const [displayFilters, setDisplayFilters] = useState(false);

  useEffect(() => {
    loadReports();
    loadFilters();
  }, []);

  useEffect(() => {
    orderReports();
  }, [displayParams.order, filter]);

  useEffect(() => {
    handleReportSearch();
  }, [search]);

  useEffect(() => {
    handleFilter();
    handleReportSearch();
  }, [
    displayParams.company,
    displayParams.vessel,
    displayParams.inspector,
    displayParams.coordinator,
  ]);

  async function loadFilters() {
    try {
      const response = await getPlanAnalysisFilters();

      setFilters({
        ...filters,
        companies: filters.companies.concat(response.companies),
        vessels: filters.vessels.concat(response.vessels),
        inspectors: filters.inspectors.concat(response.inspectors),
        coordinators: filters.coordinators.concat(response.coordinators),
      });
    } catch (error) {
      toastUtils.toaster({
        message: "Não foi possível carregar os filtros!",
        type: toastUtils.type.error,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    }
  }

  async function loadReports() {
    const skip = reports.length;
    const take = responseLength;

    try {
      const response = await getPlanAnalysisReports({
        order: displayParams.order.value ? displayParams.order.value : "",
        skip,
        take,
        filter: JSON.stringify(filter),
      });

      if (response) {
        setReports(reports.concat(response));
        setFilteredList(reports.concat(response));
      }
    } catch (error) {
      toastUtils.toaster({
        message: error.response.data,
        type: toastUtils.type.error,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    }
  }

  async function orderReports() {
    const skip = 0;
    const take = filter ? 10000 : responseLength;

    try {
      const response = await getPlanAnalysisReports({
        order: displayParams.order.value ? displayParams.order.value : "",
        skip,
        take,
        filter: JSON.stringify(filter),
      });

      if (response) {
        setReports(response);
        setFilteredList(response);
      }
    } catch (error) {
      toastUtils.toaster({
        message: error.response.data,
        type: toastUtils.type.error,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    }
  }

  function handleFilter() {
    const params = Object.keys(displayParams)
      .filter(
        (key) =>
          Object.prototype.hasOwnProperty.call(displayParams[key], "column") &&
          displayParams[key].value !== 0
      )
      .reduce((acc, key) => {
        const { column, nestedIn, value } = displayParams[key];

        if (nestedIn) {
          if (!acc[nestedIn]) {
            acc[nestedIn] = {};
          }
          acc[nestedIn][column] = value;
        } else {
          acc[column] = value;
        }

        return acc;
      }, {});

    setFilter(params);
  }

  function handleReportSearch() {
    if (!search) return handleFilter();
    const newList = reports.filter(
      (report) =>
        report.company.name.toLowerCase().includes(search.toLowerCase()) ||
        report.vessel.name.toLowerCase().includes(search.toLowerCase()) ||
        report.id === Number(search) ||
        report.traceabilityCode.toLowerCase().includes(search.toLowerCase())
    );
    setFilteredList(newList);
  }

  function handleForm({ name, value, form, setForm }) {
    setForm({ ...form, [name]: value });
  }

  return (
    <Section title="Relatórios/Análise de Planos">
      <Container theme={theme}>
        <ParamsWrapper displayFilters={displayFilters}>
          <Select
            id="order"
            icon={CgReorder}
            placeholder={"Ordenar por..."}
            enabled={true}
            list={reportUtils.planAnalysisOrderList}
            selected={displayParams.order}
            form={displayParams}
            setForm={setDisplayParams}
            handleForm={handleForm}
          />

          <FilterSelector
            displayFilters={displayFilters}
            setDisplayFilters={setDisplayFilters}
          />

          <Select
            id="company"
            icon={BiFilter}
            placeholder={"Armador"}
            enabled={true}
            list={filters.companies}
            selected={displayParams.company}
            form={displayParams}
            setForm={setDisplayParams}
            handleForm={handleForm}
            className="filter"
          />

          <Select
            id="vessel"
            icon={BiFilter}
            placeholder={"Embarcação"}
            enabled={true}
            list={
              displayParams.company.value
                ? reportUtils.dailyReportEmptyFilters.vessels.concat(
                    filters.vessels.filter(
                      (vessel) =>
                        vessel.companyId === displayParams.company.value
                    )
                  )
                : filters.vessels
            }
            selected={displayParams.vessel}
            form={displayParams}
            setForm={setDisplayParams}
            handleForm={handleForm}
            className="filter"
          />

          <Select
            id="coordinator"
            icon={BiFilter}
            placeholder={"Coordenador"}
            enabled={true}
            list={filters.coordinators}
            selected={displayParams.coordinator}
            form={displayParams}
            setForm={setDisplayParams}
            handleForm={handleForm}
            className="filter"
          />

          <Select
            id="inspector"
            icon={BiFilter}
            placeholder={"Analista"}
            enabled={true}
            list={filters.inspectors}
            selected={displayParams.inspector}
            form={displayParams}
            setForm={setDisplayParams}
            handleForm={handleForm}
            className="filter"
          />
        </ParamsWrapper>

        <AddListItem theme={theme} onClick={() => navigate(`novo`)}>
          <BsPlusCircleFill />
          <h5>Preencher Novo Relatório</h5>
        </AddListItem>

        {planAnalysisReportsLoading ? (
          <Loader />
        ) : (
          <ListWrapper
            offset={responseLength}
            list={reports}
            loadList={loadReports}
            loading={planAnalysisReportsLoading}
          >
            {filteredList.map((report, index) => (
              <ReportWrapper
                key={index}
                id={report.id}
                company={report.company}
                vessel={report.vessel}
                date={report.date}
                user={report.user}
                traceabilityCode={report.traceabilityCode}
              />
            ))}
          </ListWrapper>
        )}
      </Container>
    </Section>
  );
}

function ReportWrapper({ id, company, vessel, user, traceabilityCode }) {
  const navigate = useNavigate();

  return (
    <ListItem theme={theme} onClick={() => navigate(`${id}`)}>
      <div>
        <h5>{`[${traceabilityCode}] ${vessel.name}(${company.name})`}</h5>
        <img src={user.avatar || noPicProfile} />
      </div>
    </ListItem>
  );
}
