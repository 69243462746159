import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { BsPlusCircleFill } from "react-icons/bs";
import { CgReorder } from "react-icons/cg";
import { BiFilter } from "react-icons/bi";

import Loader from "../../../components/Loader";
import ListWrapper from "../../../components/ListWrapper";
import { AddListItem, ListItem } from "../styles";
import serviceUtils from "../../../utils/service-utils";
import theme from "../../../styles/theme";
import { Container, ServiceListItem } from "./styles";
import toastUtils from "../../../utils/toast-utils";
import useServiceFilters from "../../../hooks/api/service/useServiceFilters";
import { ParamsWrapper } from "../Enrollment/styles";
import Select from "../../../components/forms/enrollment-form/Select";
import useRequests from "../../../hooks/api/request/useRequests";
import FilterSelector from "../../../components/FilterSelector";
import useUserData from "../../../hooks/api/user/useUserData";
import noPicProfile from "../../../assets/no-pic-profile.png";
import { IoChevronForward } from "react-icons/io5";

export default function ServiceList({ allowed, types }) {
  const navigate = useNavigate();
  const responseLength = 20;
  const { getRequests, requestsLoading } = useRequests();
  const { getServiceFilters } = useServiceFilters();
  const [services, setServices] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const [filters, setFilters] = useState(serviceUtils.serviceEmptyFilters);
  const [filter, setFilter] = useState({});
  const [displayParams, setDisplayParams] = useState(
    serviceUtils.serviceEmptyDisplayParams
  );
  const search = useLocation().search.replace("?search=", "");
  const [displayFilters, setDisplayFilters] = useState(false);

  useEffect(() => {
    loadServices();
    loadFilters();
  }, []);

  useEffect(() => {
    orderServices();
  }, [displayParams.order, filter]);

  useEffect(() => {
    handleServiceSearch();
  }, [search]);

  useEffect(() => {
    handleFilter();
    handleServiceSearch();
  }, [displayParams.company, displayParams.vessel, displayParams.status]);

  function handleServiceSearch() {
    if (!search) return handleFilter();

    const newList = services.filter(
      (service) =>
        service.id === Number(search) ||
        service.details.type.toLowerCase().includes(search.toLowerCase()) ||
        service.details.company.toLowerCase().includes(search.toLowerCase()) ||
        service.details.vessel.toLowerCase().includes(search.toLowerCase())
    );

    setFilteredList(newList);
  }

  function handleForm({ name, value, form, setForm }) {
    setForm({ ...form, [name]: value });
  }

  function handleFilter() {
    const params = Object.keys(displayParams)
      .filter(
        (key) =>
          Object.prototype.hasOwnProperty.call(displayParams[key], "column") &&
          displayParams[key].value !== 0
      )
      .reduce((acc, key) => {
        acc[displayParams[key].column] = displayParams[key].value;
        return acc;
      }, {});

    setFilter(params);
  }

  async function loadServices() {
    const skip = services.length;
    const take = responseLength;

    try {
      const response = await getRequests({
        order: displayParams.order.value ? displayParams.order.value : "",
        skip,
        take,
        types,
        filter: JSON.stringify(filter),
      });

      if (response) {
        setServices(services.concat(response));
        setFilteredList(services.concat(response));
      }
    } catch (error) {
      toastUtils.toaster({
        message: error.response.data,
        type: toastUtils.type.error,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    }
  }

  async function loadFilters() {
    try {
      const response = await getServiceFilters(types[types.length - 1]);

      setFilters({
        ...filters,
        companies: filters.companies.concat(response.companies),
        vessels: filters.vessels.concat(response.vessels),
        status: filters.status.concat(response.status),
      });
    } catch (error) {
      toastUtils.toaster({
        message: "Não foi possível carregar os filtros!",
        type: toastUtils.type.error,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    }
  }

  async function orderServices() {
    const skip = 0;
    const take = filter ? 10000 : responseLength;

    try {
      const response = await getRequests({
        order: displayParams.order.value ? displayParams.order.value : "",
        skip,
        take,
        types,
        filter: JSON.stringify(filter),
      });

      if (response) {
        setServices(response);
        setFilteredList(response);
      }
    } catch (error) {
      toastUtils.toaster({
        message: error.response.data,
        type: toastUtils.type.error,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    }
  }

  return (
    <Container theme={theme}>
      <ParamsWrapper displayFilters={displayFilters}>
        <Select
          id="order"
          icon={CgReorder}
          placeholder={"Ordenar por..."}
          enabled={true}
          list={serviceUtils.serviceOrderList}
          selected={displayParams.order}
          form={displayParams}
          setForm={setDisplayParams}
          handleForm={handleForm}
        />

        <FilterSelector
          displayFilters={displayFilters}
          setDisplayFilters={setDisplayFilters}
        />

        <Select
          id="company"
          icon={BiFilter}
          placeholder={"Empresa"}
          enabled={true}
          list={filters.companies}
          selected={displayParams.company}
          form={displayParams}
          setForm={setDisplayParams}
          handleForm={handleForm}
          className="filter"
        />

        <Select
          id="vessel"
          icon={BiFilter}
          placeholder={"Embarcação"}
          enabled={true}
          list={
            displayParams.company.value
              ? serviceUtils.serviceEmptyFilters.vessels.concat(
                  filters.vessels.filter(
                    (vessel) => vessel.companyId === displayParams.company.value
                  )
                )
              : filters.vessels
          }
          selected={displayParams.vessel}
          form={displayParams}
          setForm={setDisplayParams}
          handleForm={handleForm}
          className="filter"
        />

        {types != 4 && (
          <Select
            id="status"
            icon={BiFilter}
            placeholder={"Status"}
            enabled={true}
            list={filters.status}
            selected={displayParams.status}
            form={displayParams}
            setForm={setDisplayParams}
            handleForm={handleForm}
            className="filter"
          />
        )}
      </ParamsWrapper>

      {allowed && (
        <AddListItem
          theme={theme}
          onClick={() =>
            navigate(`novo?status=${serviceUtils.status.SOLICITADO}`)
          }
        >
          <BsPlusCircleFill />
          <h5>Criar Solicitação</h5>
        </AddListItem>
      )}

      {requestsLoading ? (
        <Loader />
      ) : (
        <ListWrapper
          offset={responseLength}
          list={services}
          loadList={loadServices}
          loading={requestsLoading}
        >
          {filteredList.map((service, index) => (
            <ServiceWrapper
              key={index}
              id={service.id}
              company={service.details.company}
              vessel={service.details.vessel}
              type={service.details.type}
              status={service.statusId}
              userId={service.userId}
            />
          ))}
        </ListWrapper>
      )}
    </Container>
  );
}

function ServiceWrapper({ id, company, vessel, type, status, userId }) {
  const navigate = useNavigate();
  const { getUserData } = useUserData();
  const [user, setUser] = useState(0);

  useEffect(() => {
    loadUserData();
  }, []);

  async function loadUserData() {
    if (status === serviceUtils.status.SOLICITADO) return;

    try {
      const response = await getUserData(userId);

      if (response) setUser(response);
    } catch (error) {
      toastUtils.toaster({
        message: "Não foi possível carregar os filtros!",
        type: toastUtils.type.error,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    }
  }

  return (
    <ListItem theme={theme} onClick={() => navigate(`${id}?status=${status}`)}>
      <ServiceListItem>
        <h5>{`${id} - ${type} - ${company}/${vessel}`}</h5>
        {user ? (
          <img src={user.avatar ? user.avatar : noPicProfile} alt="" />
        ) : (
          <IoChevronForward />
        )}
      </ServiceListItem>
    </ListItem>
  );
}
