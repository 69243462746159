import useAsync from "../../useAsync";
import * as inspectionApi from "../../../services/inspectionApi";
import useToken from "../../useToken";

export default function useInspectionDetailsByProduct() {
  const token = useToken();

  const {
    data: inspectionDetailsByProduct,
    loading: inspectionDetailsByProductLoading,
    error: inspectionDetailsByProductError,
    act: getInspectionDetailsByProduct,
  } = useAsync(
    (data) => inspectionApi.getInspectionDetailsByProduct(data, token),
    false
  );

  return {
    inspectionDetailsByProduct,
    inspectionDetailsByProductLoading,
    inspectionDetailsByProductError,
    getInspectionDetailsByProduct,
  };
}
