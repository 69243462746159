import theme from "../../../../styles/theme";
import { BoxWrapper } from "./styles";

export default function CheckBox({
  label,
  form,
  handleCheck,
  id,
  disabled,
  className,
}) {
  const checked = form[id] ? true : false;

  return (
    <BoxWrapper
      theme={theme}
      checked={checked}
      disabled={disabled}
      className={className}
    >
      <input
        type="checkbox"
        value={checked}
        onChange={() => handleCheck(id)}
        disabled={disabled}
        checked={checked}
      />

      <label>{label}</label>
    </BoxWrapper>
  );
}
