import useAsync from "../../useAsync";

import * as reportApi from "../../../services/reportApi";
import useToken from "../../useToken";

export default function usePlanAnalysis() {
  const token = useToken();

  const {
    data: planAnalysis,
    loading: planAnalysisLoading,
    error: planAnalysisError,
    act: getPlanAnalysis,
  } = useAsync((data) => reportApi.getPlanAnalysis(data, token), false);

  return {
    planAnalysis,
    planAnalysisLoading,
    planAnalysisError,
    getPlanAnalysis,
  };
}
