import useAsync from "../../useAsync";

import * as reportApi from "../../../services/reportApi";
import useToken from "../../useToken";

export default function usePlanAnalysisReports() {
  const token = useToken();

  const {
    data: planAnalysisReports,
    loading: planAnalysisReportsLoading,
    error: planAnalysisReportsError,
    act: getPlanAnalysisReports,
  } = useAsync((data) => reportApi.getPlanAnalysisReports(data, token), false);

  return {
    planAnalysisReports,
    planAnalysisReportsLoading,
    planAnalysisReportsError,
    getPlanAnalysisReports,
  };
}
