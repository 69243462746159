import useAsync from "../../useAsync";

import * as requestApi from "../../../services/requestApi";
import useToken from "../../useToken";

export default function useRequestsByStatus() {
  const token = useToken();

  const {
    data: requestsByStatus,
    loading: requestsByStatusLoading,
    error: requestsByStatusError,
    act: getRequestsByStatus,
  } = useAsync((data) => requestApi.getRequestsByStatus(data, token), false);

  return {
    requestsByStatus,
    requestsByStatusLoading,
    requestsByStatusError,
    getRequestsByStatus,
  };
}
