import styled from "styled-components";

export const FormsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: relative;
  flex-direction: column;
  margin-bottom: 100px;

  p {
    color: ${({ theme }) => theme.COLORS.GRAY_100};
  }

  .textarea {
    height: 200px;

    svg {
      width: 18px;
      height: 18px;
      color: ${({ theme }) => theme.COLORS.GRAY_100};
      cursor: pointer;
      z-index: 2;
      position: absolute;
      left: 114px;
      top: 8px;
    }

    svg:hover {
      color: ${({ theme }) => theme.COLORS.WHITE};
    }
  }

  .reverse {
    max-width: 140px;
    min-width: 140px;
  }

  @media (max-width: 1023px) {
    padding: 0;
    flex-direction: column;
  }
`;

export const InfoTitle = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  svg {
    font-size: 20px;
    color: ${({ theme }) => theme.COLORS.GRAY_100};
    margin-bottom: 8px;
    cursor: pointer;
  }
`;

export const Tooltip = styled.div`
  visibility: ${({ show }) => (show ? "visible" : "hidden")};
  background-color: ${({ theme }) => theme.COLORS.BACKGROUND_TRANSLUCENT};
  color: ${({ theme }) => theme.COLORS.WHITE};
  text-align: center;
  padding: 10px;
  border-radius: 4px;
  position: absolute;
  bottom: 125%;
  left: 50%;
  transform: translateX(-50%);
  opacity: ${(props) => (props.show ? "1" : "0")};
  transition: opacity 0.3s;
  white-space: nowrap;
  z-index: 10;
`;
