import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { CgReorder } from "react-icons/cg";

import { Section } from "../../../components/Section";
import useChecklists from "../../../hooks/api/report/useChecklists";
import toastUtils from "../../../utils/toast-utils";
import theme from "../../../styles/theme";
import reportUtils from "../../../utils/report-utils";
import Select from "../../../components/forms/report-form/Select";
import { ParamsWrapper } from "../Enrollment/styles";
import { Container, ListItem } from "../styles";
import FilterSelector from "../../../components/FilterSelector";
import { BiFilter } from "react-icons/bi";
import useCheckilistFilters from "../../../hooks/api/report/useChecklistFilters";
import { IoChevronForward } from "react-icons/io5";
import Loader from "../../../components/Loader";
import ListWrapper from "../../../components/ListWrapper";

export default function Checklists() {
  const responseLength = 20;
  const { getChecklists, checklistsLoading } = useChecklists();
  const { getChecklistFilters } = useCheckilistFilters();
  const [checklists, setChecklists] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const [filters, setFilters] = useState(reportUtils.formEmptyFilters);
  const [filter, setFilter] = useState({});
  const [displayParams, setDisplayParams] = useState(
    reportUtils.formEmptyDisplayParams
  );
  const search = useLocation().search.replace("?search=", "");
  const [displayFilters, setDisplayFilters] = useState(false);

  useEffect(() => {
    loadChecklists();
    loadFilters();
  }, []);

  useEffect(() => {
    orderChecklists();
  }, [displayParams.order, filter]);

  useEffect(() => {
    handleChecklistSearch();
  }, [search]);

  useEffect(() => {
    handleFilter();
    handleChecklistSearch();
  }, [displayParams.product]);

  function handleChecklistSearch() {
    if (!search) return handleFilter();

    const newList = checklists.filter(
      (checklist) =>
        checklist.name.toLowerCase().includes(search.toLowerCase()) ||
        checklist.code.toLowerCase().includes(search.toLowerCase()) ||
        checklist.obs.toLowerCase().includes(search.toLowerCase())
    );
    setFilteredList(newList);
  }

  async function loadChecklists() {
    const skip = checklists.length;
    const take = responseLength;

    try {
      const response = await getChecklists({
        order: displayParams.order.value || "",
        skip,
        take,
        filter: JSON.stringify(filter),
      });

      if (response) {
        setChecklists(checklists.concat(response));
        setFilteredList(checklists.concat(response));
      }
    } catch (error) {
      console.log(error);
      toastUtils.toaster({
        message: error.response.data,
        type: toastUtils.type.error,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    }
  }

  async function loadFilters() {
    try {
      const response = await getChecklistFilters();

      setFilters({
        ...filters,
        products: filters.products.concat(response),
      });
    } catch (error) {
      toastUtils.toaster({
        message: "Não foi possível carregar os filtros!",
        type: toastUtils.type.error,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    }
  }

  async function orderChecklists() {
    const skip = 0;
    const take = filter ? 10000 : responseLength;

    try {
      const response = await getChecklists({
        order: displayParams.order.value || "",
        skip,
        take,
        filter: JSON.stringify(filter),
      });

      if (response) {
        setChecklists(response);
        setFilteredList(response);
      }
    } catch (error) {
      toastUtils.toaster({
        message: error.response.data,
        type: toastUtils.type.error,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    }
  }

  function handleForm({ name, value, form, setForm }) {
    setForm({ ...form, [name]: value });
  }

  function handleFilter() {
    const params = Object.keys(displayParams)
      .filter(
        (key) =>
          Object.prototype.hasOwnProperty.call(displayParams[key], "column") &&
          displayParams[key].value !== 0
      )
      .reduce((acc, key) => {
        acc[displayParams[key].column] = displayParams[key].value;
        return acc;
      }, {});

    setFilter(params);
  }

  return (
    <Section title="Relatórios/Checklists">
      <Container theme={theme}>
        <ParamsWrapper displayFilters={displayFilters}>
          <Select
            id="order"
            icon={CgReorder}
            placeholder={"Ordenar por..."}
            enabled={true}
            list={reportUtils.formOrderList}
            selected={displayParams.order}
            form={displayParams}
            setForm={setDisplayParams}
            handleForm={handleForm}
          />

          <FilterSelector
            displayFilters={displayFilters}
            setDisplayFilters={setDisplayFilters}
          />

          <Select
            id="product"
            icon={BiFilter}
            placeholder={"Serviço"}
            enabled={true}
            list={filters.products}
            selected={displayParams.product}
            form={displayParams}
            setForm={setDisplayParams}
            handleForm={handleForm}
            className="filter"
          />
        </ParamsWrapper>

        {checklistsLoading ? (
          <Loader />
        ) : (
          <ListWrapper
            offset={responseLength}
            list={checklists}
            loadList={loadChecklists}
            loading={checklistsLoading}
          >
            {filteredList.map((checklist, index) => (
              <ChecklistWrapper
                key={index}
                id={checklist.id}
                name={checklist.name}
                obs={checklist.obs}
              />
            ))}
          </ListWrapper>
        )}
      </Container>
    </Section>
  );
}

function ChecklistWrapper({ id, name, obs }) {
  const navigate = useNavigate();

  return (
    <ListItem theme={theme} onClick={() => navigate(`${id}?readOnly=true`)}>
      <div>
        <h5>{`${name} | ${obs}`}</h5>
        <IoChevronForward />
      </div>
    </ListItem>
  );
}
