import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { BiFilter } from "react-icons/bi";
import { IoChevronForward } from "react-icons/io5";

import Select from "../../../components/forms/enrollment-form/Select";
import { Section } from "../../../components/Section";
import theme from "../../../styles/theme";
import { Container, ListItem } from "../styles";
import dpcUtils from "../../../utils/dpc-utils";
import { ParamsWrapper } from "../Enrollment/styles";
import FilterSelector from "../../../components/FilterSelector";
import useDpcCertificates from "../../../hooks/api/dpc/useDpcCertificates";
import toastUtils from "../../../utils/toast-utils";
import useDpcCertificateFilters from "../../../hooks/api/dpc/useDpcCertificateFilters";
import ListWrapper from "../../../components/ListWrapper";

export function DpcCertificates() {
  const responseLength = 20;
  const { getDpcCertificates, dpcCertificatesLoading } = useDpcCertificates();
  const { getDpcCertificateFilters } = useDpcCertificateFilters();
  const [certificates, setCertificates] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const [filters, setFilters] = useState(dpcUtils.certificateEmptyFilters);
  const [filter, setFilter] = useState({});
  const [displayParams, setDisplayParams] = useState(
    dpcUtils.certificateEmptyDisplayParams
  );
  const search = useLocation().search.replace("?search=", "");
  const [displayFilters, setDisplayFilters] = useState(false);

  useEffect(() => {
    loadCertificates();
    loadFilters();
  }, [filter]);

  useEffect(() => {
    handleFilter();
    handleCertificateSearch();
  }, [search, displayParams]);

  async function loadCertificates() {
    const skip = filter ? 0 : certificates.length;
    const take = filter ? 10000 : responseLength;

    try {
      const response = await getDpcCertificates({
        skip,
        take,
        filter: JSON.stringify(filter),
      });

      if (response) {
        if (filter) {
          setCertificates(response);
          setFilteredList(response);
        } else {
          setCertificates(certificates.concat(response));
          setFilteredList(certificates.concat(response));
        }
      }
    } catch (error) {
      toastUtils.toaster({
        message: error.response.data,
        type: toastUtils.type.error,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    }
  }

  async function loadFilters() {
    try {
      const response = await getDpcCertificateFilters();

      setFilters({
        ...dpcUtils.certificateEmptyFilters,
        companies: dpcUtils.certificateEmptyFilters.companies.concat(
          response.companies
        ),
        vessels: dpcUtils.certificateEmptyFilters.vessels.concat(
          response.vessels
        ),
      });
    } catch (error) {
      toastUtils.toaster({
        message: "Não foi possível carregar os filtros!",
        type: toastUtils.type.error,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    }
  }

  function handleForm({ name, value, form, setForm }) {
    setForm({ ...form, [name]: value });
  }

  function handleCertificateSearch() {
    if (!search) return handleFilter();
    const newList = certificates.filter(
      (certificate) =>
        certificate.name.toLowerCase().includes(search.toLowerCase()) ||
        certificate.vessel.name.includes(search.toLowerCase()) ||
        certificate.company.name.includes(search.toLowerCase())
    );
    setFilteredList(newList);
  }

  function handleFilter() {
    const params = Object.keys(displayParams)
      .filter(
        (key) =>
          Object.prototype.hasOwnProperty.call(displayParams[key], "column") &&
          displayParams[key].value !== 0
      )
      .reduce((acc, key) => {
        const { column, nestedIn, value } = displayParams[key];

        if (nestedIn) {
          if (!acc[nestedIn]) {
            acc[nestedIn] = {};
          }
          acc[nestedIn][column] = value;
        } else {
          acc[column] = value;
        }

        return acc;
      }, {});

    setFilter(params);
  }

  return (
    <Section title="Certificados">
      <Container theme={theme}>
        <ParamsWrapper displayFilters={displayFilters}>
          <FilterSelector
            displayFilters={displayFilters}
            setDisplayFilters={setDisplayFilters}
          />

          <Select
            id="company"
            icon={BiFilter}
            placeholder={"Armador"}
            enabled={true}
            list={filters.companies}
            selected={displayParams.company}
            form={displayParams}
            setForm={setDisplayParams}
            handleForm={handleForm}
            className="filter"
          />

          <Select
            id="vessel"
            icon={BiFilter}
            placeholder={"Embarcação"}
            enabled={true}
            list={
              displayParams.company.value
                ? dpcUtils.certificateEmptyFilters.vessels.concat(
                    filters.vessels.filter(
                      (vessel) =>
                        vessel.companyId === displayParams.company.value
                    )
                  )
                : filters.vessels
            }
            selected={displayParams.vessel}
            form={displayParams}
            setForm={setDisplayParams}
            handleForm={handleForm}
            className="filter"
          />
        </ParamsWrapper>

        <ListWrapper
          offset={responseLength}
          list={certificates}
          loadList={loadCertificates}
          loading={dpcCertificatesLoading}
        >
          {filteredList.map((certificate, index) => (
            <CertificateWrapper
              key={index}
              id={certificate.id}
              name={certificate.name}
              vessel={certificate.vessel}
            />
          ))}
        </ListWrapper>
      </Container>
    </Section>
  );
}

function CertificateWrapper({ id, name, vessel }) {
  const navigate = useNavigate();

  return (
    <ListItem theme={theme} onClick={() => navigate(`${id}`)}>
      <div>
        <h5>{`${vessel.name} - ${name}`}</h5>
        <IoChevronForward />
      </div>
    </ListItem>
  );
}
