import useAsync from "../../useAsync";

import * as dpcApi from "../../../services/dpcApi";
import useToken from "../../useToken";

export default function useDpcReport() {
  const token = useToken();

  const {
    data: dpcReport,
    loading: dpcReportLoading,
    error: dpcReportError,
    act: getDpcReport,
  } = useAsync((data) => dpcApi.getReport(data, token), false);

  return {
    dpcReport,
    dpcReportLoading,
    dpcReportError,
    getDpcReport,
  };
}
