import useAsync from "../../useAsync";
import * as inspectionApi from "../../../services/inspectionApi";
import useToken from "../../useToken";

export default function useAssignAnalysis() {
  const token = useToken();

  const {
    loading: assignAnalysisLoading,
    error: assignAnalysisError,
    act: assignAnalysis,
  } = useAsync((data) => inspectionApi.assignAnalysis(data, token), false);

  return {
    assignAnalysisLoading,
    assignAnalysisError,
    assignAnalysis,
  };
}
