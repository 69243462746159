import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { LiaClipboardListSolid, LiaToolsSolid } from "react-icons/lia";
import { BiBarcode } from "react-icons/bi";

import enrollmentUtils from "../../../../utils/enrollment-utils";
import { Container, FormsWrapper, Form, Row } from "../styles";
import theme from "../../../../styles/theme";
import Label from "../../../../components/forms/enrollment-form/Label";
import Input from "../../../../components/forms/enrollment-form/Input";
import Select from "../../../../components/forms/enrollment-form/Select";
import useProduct from "../../../../hooks/api/product/useProduct";
import useCertifications from "../../../../hooks/api/product/useCertifications";
import useProjects from "../../../../hooks/api/product/useProjects";
import toastUtils from "../../../../utils/toast-utils";
import { ProductContainer } from "./styles";
import Button from "../../../../components/forms/enrollment-form/Button";
import useSaveProduct from "../../../../hooks/api/product/useSaveProduct";
import UserContext from "../../../../contexts/UserContext";

export default function Product({ title, setTitle }) {
  const { userData } = useContext(UserContext);
  const path = useLocation().pathname;
  const navigate = useNavigate();
  const { productId } = useParams();
  const newProduct = isNaN(productId);
  const allowed = newProduct && userData.department.label === "DIRETORIA";
  const { getProduct } = useProduct();
  const { getCertifications } = useCertifications();
  const { getProjects } = useProjects();
  const { saveProduct, saveProductLoading } = useSaveProduct();
  const [activities, setActivities] = useState([{ label: "", value: 0 }]);
  const [services, setServices] = useState([
    { label: "", value: 0, activityId: 0 },
  ]);
  const [products, setProducts] = useState([]);
  const [form, setForm] = useState(enrollmentUtils.emptyProduct);
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    loadProductData();
  }, []);

  useEffect(() => {
    loadProducts();
  }, [form.service]);

  useEffect(() => {
    handleProductCode();
  }, [products]);

  async function loadProductData() {
    try {
      const response = await getProduct(productId);

      if (response) {
        setActivities(response.activities);
        setServices(response.services);

        if (newProduct) {
          setTitle(`${title}/Novo Cadastro`);
          return;
        }

        setForm(response.product);
        setTitle(
          `${title.replace("/Novo Cadastro", "")}/${response.product.name}`
        );
      } else {
        setForm(enrollmentUtils.emptyCompany);
      }
    } catch (error) {
      toastUtils.toaster({
        message: error.response.data,
        type: toastUtils.type.error,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    }
  }

  async function loadProducts() {
    if (!form.activity.value || !form.service.value) return;

    let response;

    try {
      let activity;

      if (form.activity.value === 1) {
        activity = "certifications";
        response = await getCertifications("");
      }
      if (form.activity.value === 2) {
        activity = "projects";
        response = await getProjects("");
      }

      if (response) {
        response = response[activity].filter(
          (product) => product.serviceId === form.service.value
        );
        setProducts(response);
      }
    } catch (error) {
      toastUtils.toaster({
        message: error.response.data,
        type: toastUtils.type.error,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    }
  }

  async function handleProductCode() {
    if (!allowed || products.length === 0) return;

    const code = products[products.length - 1].id + 1;

    handleForm({
      name: "id",
      value: code,
    });
  }

  function handleForm({ name, value }) {
    if (name === "acronym" && value.length > 3) return;

    setForm({ ...form, [name]: value });
  }

  async function handleSubmit(e) {
    e.preventDefault();

    setSubmitted(true);

    try {
      const body = {
        ...form,
        activity: form.activity.value,
        service: form.service.value,
      };

      const response = await saveProduct(body);
      setTitle(`${title.replace("/Novo Cadastro", "")}/${response.name}`);
      navigate(`${path.replace("/novo", "")}/${response.id}`);

      toastUtils.toaster({
        message: "Informações salvas com sucesso!",
        type: toastUtils.type.success,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    } catch (error) {
      toastUtils.toaster({
        message: error.response.data,
        type: toastUtils.type.error,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    }
  }

  return (
    <Container theme={theme}>
      <ProductContainer theme={theme}>
        <FormsWrapper>
          <Form className="productForm" onSubmit={handleSubmit}>
            <div>
              <h3>Produto</h3>

              <Row>
                <span className="activity">
                  <Label id="activity" text="Atividade*" />
                  <Select
                    id="activity"
                    placeholder="Selecione..."
                    icon={LiaClipboardListSolid}
                    enabled={allowed}
                    required
                    submitted={submitted}
                    list={activities}
                    selected={form.activity}
                    form={form}
                    setForm={setForm}
                    handleForm={handleForm}
                  />
                </span>

                <span className="service">
                  <Label id="service" text="Serviço*" />
                  <Select
                    id="service"
                    placeholder="Selecione..."
                    icon={LiaToolsSolid}
                    enabled={allowed && form.activity.value}
                    required
                    submitted={submitted}
                    list={services.filter(
                      (service) => service.activityId === form.activity.value
                    )}
                    selected={form.service}
                    form={form}
                    setForm={setForm}
                    handleForm={handleForm}
                  />
                </span>
              </Row>

              <Row className="desktop">
                <span className="id">
                  <Label id="id" text="Código*" />
                  <Input
                    id="id"
                    placeholder="Código"
                    type="text"
                    icon={BiBarcode}
                    disabled
                    required
                    submitted={submitted}
                    value={form.id}
                    onChange={(e) =>
                      handleForm({
                        name: e.target.id,
                        value: e.target.value,
                      })
                    }
                  />
                </span>

                <span className="acronym">
                  <Label id="acronym" text="Sigla*" />
                  <Input
                    id="acronym"
                    placeholder="Sigla"
                    type="text"
                    icon={BiBarcode}
                    disabled={!allowed}
                    required
                    submitted={submitted}
                    value={form.acronym}
                    pattern="^[A-Z]{3}$"
                    onChange={(e) =>
                      handleForm({
                        name: e.target.id,
                        value: e.target.value.toUpperCase(),
                      })
                    }
                  />
                </span>

                <span className="name">
                  <Label id="name" text="Nome do produto*" />
                  <Input
                    id="name"
                    placeholder="Nome do produto"
                    type="text"
                    icon={BiBarcode}
                    disabled={!allowed}
                    required
                    submitted={submitted}
                    value={form.name}
                    pattern={"^[A-ZÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇÑ]+$"}
                    onChange={(e) =>
                      handleForm({
                        name: e.target.id,
                        value: e.target.value,
                      })
                    }
                  />
                </span>
              </Row>

              <Row className="mobile">
                <span className="id">
                  <Label id="id" text="Código*" />
                  <Input
                    id="id"
                    placeholder="Código"
                    type="text"
                    icon={BiBarcode}
                    disabled
                    required
                    submitted={submitted}
                    value={form.id}
                    onChange={(e) =>
                      handleForm({
                        name: e.target.id,
                        value: e.target.value,
                      })
                    }
                  />
                </span>

                <span className="acronym">
                  <Label id="acronym" text="Sigla*" />
                  <Input
                    id="acronym"
                    placeholder="Sigla"
                    type="text"
                    icon={BiBarcode}
                    disabled={!allowed}
                    required
                    submitted={submitted}
                    value={form.acronym}
                    pattern="^[A-Z]{3}$"
                    onChange={(e) =>
                      handleForm({
                        name: e.target.id,
                        value: e.target.value.toUpperCase(),
                      })
                    }
                  />
                </span>
              </Row>

              <Row className="mobile">
                <span className="name">
                  <Label id="name" text="Nome do produto*" />
                  <Input
                    id="name"
                    placeholder="Nome do produto"
                    type="text"
                    icon={BiBarcode}
                    disabled={!allowed}
                    required
                    submitted={submitted}
                    value={form.name}
                    pattern={"^[a-zA-Záàâãéèêíïóôõöúçñ0-9 ]+$"}
                    onChange={(e) =>
                      handleForm({
                        name: e.target.id,
                        value: e.target.value,
                      })
                    }
                  />
                </span>
              </Row>
            </div>

            <Button
              className="submit"
              title="Salvar"
              enabled={allowed && !saveProductLoading}
            />
            {allowed && (
              <Button
                className="cancel"
                title="Cancelar"
                enabled={allowed && !saveProductLoading}
                type="button"
                onClick={() => navigate(-1)}
              />
            )}
          </Form>
        </FormsWrapper>
      </ProductContainer>
    </Container>
  );
}
