import { FaFileDownload } from "react-icons/fa";
import { RotatingLines } from "react-loader-spinner";

import { Container } from "./styles";
import theme from "../../../../styles/theme";

export default function DownloadButton({ loading, action, margin }) {
  return (
    <Container theme={theme} onClick={action} margin={margin}>
      {loading ? (
        <RotatingLines
          height="80"
          width="80"
          color={theme.COLORS.WHITE}
          strokeColor={theme.COLORS.WHITE}
          ariaLabel="tail-spin-loading"
          radius="1"
          visible={true}
        />
      ) : (
        <FaFileDownload />
      )}
    </Container>
  );
}
